import { X } from 'lucide-react'
import React from 'react'
import { useTimeLeftUntilMidnight } from '~/utils/useTimeLeftUntilMidnight';

interface PersonaBannerProps {
    setIsBannerVisible: (visible: boolean) => void;
}

const PersonaBanner: React.FC<PersonaBannerProps> = ({ setIsBannerVisible }) => {
    const { timeLeft } = useTimeLeftUntilMidnight();

    return (
        <div className="relative flex flex-wrap xl:flex-nowrap bg-[#9C68EF] py-3 sm:py-[18px] px-[20px] md:px-[85px] text-white text-[15px] sm:text-[18px] font-normal leading-[33px] items-center">
            <button
                className="absolute hidden sm:flex top-1 right-1 text-white p-1"
                onClick={() => setIsBannerVisible(false)}
            >
                <X size={20} />
            </button>

            <div className="flex items-center justify-center sm:justify-start flex-wrap w-full md:w-auto xl:flex-nowrap">
                <span className="flex-shrink-0">Kostenlose Texte wieder verfügbar in</span>
                <p className="ms-2 sm:w-[124px] text-[15px] sm:text-[18px]">{timeLeft}</p>
            </div>

            <div className="flex w-full md:w-auto justify-center my-2 md:my-0 ml-0 mr-3">
                <a href="/upgrade">
                    <button className="h-[40px] w-[116px] rounded-[4px] leading-[33px] bg-white text-[#9C68EF]">
                        Upgrade
                    </button>
                </a>
            </div>

            <div className="flex items-center justify-center sm:justify-start flex-wrap w-full md:w-auto xl:flex-nowrap">
                <span className="flex-shrink-0">oder</span>
                <a href="/" className="mx-2 underline underline-white">
                    hier mehr Texte
                </a>
                <span className="flex-shrink-0">erhalten</span>
            </div>
        </div>
    )
}

export default PersonaBanner