import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useShareableData } from '../../providers/ShareableDataProvider';

interface OnboardingBoxProps {
  setActiveModal: () => void;
  steps: any[];
  completedStepsLength: number;
  completionPercentage: number;
  showCloseButton: boolean;  // Add this prop
}

const OnboardingBox: React.FC<OnboardingBoxProps> = ({ setActiveModal, steps, completedStepsLength, completionPercentage, showCloseButton }) => {
  const { closeOnboardingMenu } = useShareableData();

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    closeOnboardingMenu();
  };

  return (
    <div className="bg-[url('assets/bg-onboarding.png')] mx-2 sm:mx-0 mb-7 p-2.5 pt-1 bg-neutral-800 bg-cover bg-center rounded-md text-white cursor-pointer hidden-when-sidebar-collapsed" onClick={setActiveModal} >
      {showCloseButton ?( <div className="flex justify-end">
        <button onClick={handleClose}><FontAwesomeIcon className="text-xs" icon={faTimes} /></button>
      </div>
      ):(
      <div className="flex justify-end mt-2">
          {/*<button onClick={handleClose}><FontAwesomeIcon className="text-xs" icon={faTimes}/></button>*/}
      </div>)}
        <div className="flex items-center">
            <span className="text-[10px] font-semibold me-1">Einführung abschließen</span>
            <span className="text-xs"> 🙌</span>
        <FontAwesomeIcon icon={faArrowRight} className="pl-2 text-xs transition-colors duration-200" />
      </div>
      <div className='flex h-3 mt-2 justify-between'>
        {steps.map((_, index) => {
          const baseClass = "w-[22px] h-1";
          const activeClass = index < completedStepsLength ? "bg-gray-300" : "bg-gray-500";
          const roundedClass = index === 0 ? "rounded-l-[60px]" : (index === steps.length - 1 ? "rounded-r-[60px]" : "");
          return (<div key={index} className={`${baseClass} ${activeClass} ${roundedClass}`}></div>);
        })}
      </div>
      <p className="text-[10px] text-center mt-2">{completionPercentage} % abgeschlossen</p>
    </div>
  );
}

export default OnboardingBox;