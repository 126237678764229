import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyPasswordResetCode, confirmPasswordReset, applyActionCode } from "firebase/auth";
import { toast } from "react-toastify";
import Container from "../../components/Container";
import { ClosedEye, Eye } from "../../assets/Icons";
import { auth } from "../../config/fbConfig";
import SpinningLoader from "../../components/loader/spinning_loader";
import { usePostHog } from 'posthog-js/react';
import { handleUserRedirect, logoutUser } from "../../utils/loginUtils";


// Implemented according to this https://firebase.google.com/docs/auth/custom-email-handler
const AccountManagementPage = () => {
    // State variables
    const [loading, setLoading] = useState(true);
    const [isShowPassword, setShowPassword] = useState(false);
    const [isShowConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: ""
    });

    // Functions
    const navigate = useNavigate();
    const posthog = usePostHog();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        validateField(name, value);
    };

    const getParameterByName = (name: string) => {
        const url = window.location.href;
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormValid(Object.values(formErrors).every((error) => error === ""));
        if (formValid) {
            const actionCode = getParameterByName('oobCode');
            if (actionCode === null) {
                toast.error(
                    "Der Passwort-Reset ist ungültig oder abgelaufen. Bitte versuchen Sie es erneut",
                    {
                        position: "top-right",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
                navigate("/sign-in");
            } else {
                confirmPasswordReset(auth, actionCode, formData.password)
                    .then((resp) => {
                        toast.success(
                            "Passwort erfolgreich geändert",
                            {
                                position: "top-right",
                                autoClose: 7000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            }
                        );
                        navigate("/sign-in");
                    })
                    .catch(() => {
                        toast.error(
                            "Der Passwort-Reset ist ungültig oder abgelaufen. Bitte versuchen Sie es erneut",
                            {
                                position: "top-right",
                                autoClose: 7000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            }
                        );
                    });
            }
        }
    };

    const [formErrors, setFormErrors] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    });

    const validateField = (fieldName: string, value: string) => {
        let fieldValidationErrors = formErrors;

        switch (fieldName) {
            case "email":
                fieldValidationErrors.email = value.match(
                    /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
                )
                    ? ""
                    : "E-Mail ist ungültig";
                break;
            case "password":
                const hasUpperCase = value.toLowerCase() !== value;
                const hasNumber = /\d/.test(value);
                const hasSpecialCharacter = /[ \W|_]/.test(value);

                if (value.length < 8) {
                    fieldValidationErrors.password =
                        "Das Passwort muss mindestens 8 Zeichen lang sein.";
                } else if (!hasUpperCase) {
                    fieldValidationErrors.password =
                        "Passwort muss mindestens einen Großbuchstaben enthalten.";
                } else if (!hasNumber) {
                    fieldValidationErrors.password =
                        "Passwort muss mindestens eine Zahl enthalten.";
                } else if (!hasSpecialCharacter) {
                    fieldValidationErrors.password =
                        "Passwort muss mindestens ein Sonderzeichen enthalten.";
                } else {
                    fieldValidationErrors.password = "";
                }
                break;
            case "confirmPassword":
                fieldValidationErrors.confirmPassword =
                    value !== formData.password ? "Passwörter stimmen nicht überein" : "";
                break;
            default:
                break;
        }

        setFormErrors({ ...fieldValidationErrors });
    };

    useEffect(() => {
        // Get the action to complete.
        const mode = getParameterByName('mode');
        // Get the one-time code from the query parameter.
        const actionCode = getParameterByName('oobCode');

        if (mode === null || actionCode === null) {
            // Invalid or missing URL parameters; do something here.
            return;
        }

        // Handle the user management action.
        switch (mode) {
            case 'resetPassword':
                // Display reset password handler and UI.
                handleResetPassword(actionCode);
                break;
            case 'verifyEmail':
                // Display email verification handler and UI.
                handleVerifyEmail(actionCode);
                break;
            default:
            // Error: invalid mode.
        }
    // Disable the eslint warning here, since we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleResetPassword(actionCode: string) {
        // Verify the password reset code is valid.
        verifyPasswordResetCode(auth, actionCode).then(() => {
            setLoading(false);
            // Once loading is false, it shows the form
            // When the form is submitted, call confirmPasswordReset with the new password
        }).catch(() => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            toast.error(
                "Der Passwort-Reset ist ungültig oder abgelaufen. Bitte versuchen Sie es erneut",
                {
                    position: "top-right",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }
            );
        });
    }

    // function to handle the logout
    const handleLogout = () => {
        logoutUser(auth, navigate);
    }

    function handleVerifyEmail(actionCode: string) {
        // Try to apply the email verification code.
        applyActionCode(auth, actionCode).then(async (resp) => {
            // Email address has been verified.
            // reload the current user
            // for some godforsaken reason (that is not documented anywhere)
            // the emailVerified property is not updated after the email is verified
            // so we need to reload the user to get the updated value
            // https://stackoverflow.com/questions/37900447/user-emailverified-doesnt-change-after-clicking-email-verification-link-firebas
            const user = auth.currentUser;

            if (user) {
                // If the user is logged in
                // (Meaning if the Verification-Link is opened in the same browser as the user is logged in)
                // reload the user, to get the updated emailVerified property
                // and send the user to /welcome
                await user.reload();

                // Posthog event email_verified
                posthog.capture('verify_email:successful_verify', {
                    email: user.email,
                });

                // Use the handleUserRedirect function to redirect the user to the correct page
                handleUserRedirect(auth as any, navigate, posthog);
            } else {
                // If the user is not logged in
                // (Meaning if the Verification-Link is opened in a different browser than the user is logged in)
                // send the user to /sign-in and show a toast informing the user of the successful verification
                toast.success("Ihre Email-Adresse wurde erfolgreich verifiziert. Bitte loggen Sie sich ein",
                {
                    position: "top-right",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                // Posthog event email_verified, without email, because the user is not logged in
                posthog.capture('verify_email:successful_verify', {});

                navigate("/sign-in");
            }
        }).catch((error) => {
            // If the user is already verified, send them to /templates
            if (error.code === "auth/email-already-verified") {
                handleUserRedirect(auth as any, navigate, posthog);
            } else {
            // Code is invalid or expired. Ask the user to verify their email address
            // again.
            toast.error(
                "Die Verifizierung ist ungültig oder abgelaufen. Bitte verifizieren Sie ihre E-Mail-Adresse erneut",
                {
                    position: "top-right",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }
            );
            navigate("/sign-in");
            }
        });

    }

    if (loading === true)
        return (
            <SpinningLoader/>
        );

    return (
        <div>
            <main className="min-h-screen flex flex-col">
                <section className="flex-1 py-8 flex flex-col justify-center">
                    <Container>
                        <div className="flex flex-col justify-center">
                            <div className="flex flex-col py-4 lg:hidden">
                                <h4 className="text-primary font-semibold text-[28px]">
                                Neues Passwort setzen:
                                </h4>
                            </div>
                            <div className="relative">
                                <div className="flex items-center flex-wrap justify-center w-full">
                                    <div className="w-full mb-4 order-2 lg:order-1 lg:w-[45%]">
                                        <div className="text-white rounded-2xl">
                                            <div className="lg:flex flex-col hidden">
                                                <h4 className="text-primary font-semibold text-[28px]">
                                                    Neues Passwort setzen:
                                                </h4>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                                <ul className="flex pt-6 space-y-5 flex-col">
                                                    <li>
                                                        <div>
                                                            <label
                                                                htmlFor="password"
                                                                className="mb-1 block text-base font-medium text-primary"
                                                            >
                                                                Neues Passwort
                                                            </label>
                                                            <div className="relative">
                                                                <input
                                                                    type={isShowPassword ? "text" : "password"}
                                                                    name="password"
                                                                    id="password"
                                                                    className="block w-full border-0 p-3 font-light text-primary-500 bg-desertStrom placeholder-gray-500 focus:ring-0 rounded-lg text-sm"
                                                                    placeholder="Passwort"
                                                                    required
                                                                    value={formData.password}
                                                                    onChange={handleChange}
                                                                />
                                                                <span
                                                                    onClick={() => setShowPassword(!isShowPassword)}
                                                                    className="absolute right-3 top-1/2 -translate-y-1/2"
                                                                >
                                                                    {isShowPassword ? <ClosedEye /> : <Eye />}
                                                                </span>
                                                            </div>
                                                            {formErrors.password.length > 0 && (
                                                                <span className="error mt-2 text-xs block text-red-500">
                                                                    {formErrors.password}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <label
                                                                htmlFor="confirmPassword"
                                                                className="mb-1 block text-base font-medium text-primary"
                                                            >
                                                                Passwort bestätigen
                                                            </label>
                                                            <div className="relative">
                                                                <input
                                                                    type={
                                                                        isShowConfirmPassword ? "text" : "password"
                                                                    }
                                                                    name="confirmPassword"
                                                                    id="confirmPassword"
                                                                    className="block w-full border-0 p-3 font-light text-primary-500 bg-desertStrom placeholder-gray-500 focus:ring-0 rounded-lg text-sm"
                                                                    placeholder="Bestätigen Sie Ihr Passwort"
                                                                    required
                                                                    value={formData.confirmPassword}
                                                                    onChange={handleChange}
                                                                />

                                                                <span
                                                                    onClick={() =>
                                                                        setShowConfirmPassword(!isShowConfirmPassword)
                                                                    }
                                                                    className="absolute right-3 top-1/2 -translate-y-1/2"
                                                                >
                                                                    {isShowConfirmPassword ? (
                                                                        <ClosedEye />
                                                                    ) : (
                                                                        <Eye />
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {formErrors.confirmPassword.length > 0 && (
                                                                <span className="error mt-2 text-xs block text-red-500">
                                                                    {formErrors.confirmPassword}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="flex justify-center space-x-4 ">
                                                            <button
                                                                type="submit"
                                                                className="btn-default w-full rounded-lg font-Outfit bg-secondary text-lg h-12 text-white font-bold tracking-[0.16px] border-2 border-secondary transition-all duration-200 ease-in-out px-8 hover:bg-transparent hover:text-secondary"
                                                            >
                                                                Passwort ändern
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="inline-flex items-center px-8 py-4 rounded-lg font-Outfit bg-grey text-lg h-12 text-black font-bold tracking-[0.16px] border-2 border-grey transition-all duration-200 ease-in-out px-8 hover:bg-transparent hover:text-grey"
                                                                onClick={handleLogout}
                                                            >
                                                                Logout
                                                            </button>

                                                        </div>
                                                    </li>
                                                </ul>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        </div>
    );
};

export default AccountManagementPage;