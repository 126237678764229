import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-modal";
import SpinningLoader from "../../components/loader/spinning_loader";
import { useNavigate } from "react-router-dom";
import { usePostHog } from 'posthog-js/react';
import firebase, { auth } from "../../config/fbConfig";
import { logoutUser } from "../../utils/loginUtils";
import { useManageStripeSubscription } from "../../utils/ManageStripeSubscription";
import { LoaderIcon } from 'lucide-react';

interface Product {
    name: string;
    active: boolean;
    prices: Array<{ priceId: string; priceData: any }>;
    id: string;
    // Add other properties as needed
}

type UpgradeModalProps = {
    isOpen: boolean;
    onRequestClose: () => void;
};

const UpgradeModal: React.FC<UpgradeModalProps> = ({ isOpen, onRequestClose }) => {
    const [isAnnual, setIsAnnual] = useState(true);
    const [products, setProducts] = useState<Record<string, Product>>({});
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const posthog = usePostHog();
    const navigate = useNavigate();
    const [subscriptionState, setSubscriptionState] = useState<"free" | "subscribed">("free");
    const [isUpgrading, setIsUpgrading] = useState(false);
    const manageStripeSubscription = useManageStripeSubscription();

    // Use useMemo to create a memoized prices object
    const prices = useMemo(() => {
        const getPriceForPlan = (planName: string, period: string) => {
            const plan = Object.values(products).find((p) => p.name === planName && p.active);
            if (!plan) return 0;

            const price = plan.prices.find(p => p.priceData.interval === period && p.priceData.active === true);
            if (price && price.priceData && price.priceData.unit_amount) {
                return price.priceData.unit_amount / 100;
            }
            return 0;
        };

        return {
            Starter: {
                monthly: getPriceForPlan("Starter", "month"),
                yearly: getPriceForPlan("Starter", "year"),
            },
            Creator: {
                monthly: getPriceForPlan("Creator", "month"),
                yearly: getPriceForPlan("Creator", "year"),
            },
        };
    }, [products]);

    const upgradeUser = async (priceId: any) => {
        setLoading(true);

        // Posthog event upgrade_page:upgrade_user_function
        posthog.capture('upgrade_page:upgrade_user_function', { price_id: priceId })

        const uid = auth?.currentUser?.uid;
        const urlPrefix = window.location.href.includes("localhost")
            ? "http://localhost:3000"
            : window.location.href.includes("tst-robowriter")
                ? "https://tst-robowriter.web.app/"
                : "https://app.robowriter.de";

        // Create a new Checkout Session for the order
        const checkoutSessionRef = await firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .collection("checkout_sessions").doc()

        await checkoutSessionRef.set({
            price: priceId,
            allow_promotion_codes: true,
            automatic_tax: true,
            success_url: `${urlPrefix}/success?priceId=${priceId}`,
            cancel_url: `${urlPrefix}`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });

        const unsubscribe = checkoutSessionRef.onSnapshot(async (snap: any) => {
            const { error, url }: any = snap.data();
            if (error) {
                alert(error.message);
            }
            if (url) {
                window.location.href = url;
              }
        });
        setTimeout(() => {
            unsubscribe();
            console.warn("Timeout: checkout session not found.");
            logoutUser(auth, navigate);
            navigate("/404");
        }, 30000);
    };

    useEffect(() => {
        setIsLoading(true);
        firebase
            .firestore()
            .collection("products")
            .where("active", "==", true)
            .get()
            .then((snapshot) => {
                const prod: Record<string, Product> = {};
                snapshot.forEach((productDoc) => {
                    const productData = productDoc.data() as Product;

                    // Add productDoc.id to productData
                    productData.id = productDoc.id;

                    prod[productDoc.id] = { ...productData, prices: [] };
                });

                Promise.all(
                    Object.values(prod).map(async (product) => {

                        const priceSnapshot = await firebase
                            .firestore()
                            .collection("products")
                            .doc(product.id)
                            .collection("prices")
                            .get();

                        product.prices = priceSnapshot.docs.map((priceDoc) => ({
                            priceId: priceDoc.id,
                            priceData: priceDoc.data(),
                        }));
                    })
                ).then(() => {
                    setProducts(prod);
                    setIsLoading(false);
                });
            });
    }, []);

    useEffect(() => {
        const getSubscriptionStatus = async () => {
            const userDoc = await firebase.firestore().collection("users").doc(auth.currentUser!.uid).get();
            if (userDoc.exists) {
                const userData = userDoc.data();
                if (userData && !userData.freetrial) {
                    setSubscriptionState("subscribed");
                } else {
                    setSubscriptionState("free");
                }
            }
        };

        getSubscriptionStatus();
    }, []);

    const handleUpgrade = async () => {
        setIsUpgrading(true);
        if (subscriptionState === "subscribed") {
            await manageStripeSubscription();
        } else {
            // Existing upgrade logic for free users
            const priceId = getPriceIdForPlan(isAnnual ? "Creator" : "Starter", isAnnual ? "year" : "month");
            await upgradeUser(priceId);
        }
        // setIsUpgrading(false);
    };

    const renderPrice = (planName: "Starter" | "Creator") => {
        if (isLoading) {
            return <div className="text-sm text-gray-500">Loading...</div>;
        }
        const monthlyPrice = prices[planName].monthly;
        const yearlyPrice = prices[planName].yearly / 12;
        return (
            <div className="flex items-center">
                <div className={`text-2xl ${isAnnual ? 'text-grey-500' : 'text-grey-600'}`}>
                    <span className={isAnnual ? 'line-through' : ''}>{monthlyPrice}</span><span className="text-xs font-normal text-grey-400">€</span>
                </div>
                {isAnnual && (
                    <div className="ml-2 text-md text-grey-600">
                        {yearlyPrice.toFixed(2)}<span className="text-xs text-grey-400">€</span>
                    </div>
                )}
            </div>
        );
    };

    /**
     * Get the price ID for a given plan and period.
     * @param planName - The name of the plan ("Starter" or "Creator")
     * @param period - The billing period ("month" or "year")
     * @returns The price ID for the specified plan and period, or an empty string if not found
     */
    const getPriceIdForPlan = (planName: "Starter" | "Creator", period: "month" | "year"): string => {
        try {
            // Find the active plan with the given name
            const plan = Object.values(products).find((p) => p.name === planName && p.active);

            if (!plan) {
                console.warn(`No active plan found for ${planName}`);
                return "";
            }

            // Find the price for the given period that is active
            const price = plan.prices.find(p => p.priceData.interval === period && p.priceData.active === true);

            if (!price) {
                console.warn(`No active price found for ${planName} with period ${period}`);
                return "";
            }

            if (!price.priceId) {
                console.warn(`Price ID is missing for ${planName} with period ${period}`);
                return "";
            }

            return price.priceId;
        } catch (error) {
            console.error(`Error in getPriceIdForPlan: ${error}`);
            return "";
        }
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                className="inline-block bg-white align-bottom shadow-xl transform text-left transition-all sm:my-8 sm:align-middle w-11/12 p-0 sm:max-w-2xl rounded-3xl overflow-hidden"
                overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
            >
                <style>{`
                    .custom-scrollbar::-webkit-scrollbar {
                        width: 10px;
                    }
        
                    .custom-scrollbar::-webkit-scrollbar-track {
                        background: transparent; 
                    }
        
                    .custom-scrollbar::-webkit-scrollbar-thumb {
                        background: #888; 
                        border-radius: 10px;
                    }
        
                    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                        background: #555; 
                    }
        
                    /* Buttons */
                    .custom-scrollbar::-webkit-scrollbar-button {
                        display: none;
                    }
                `}</style>
                <div className="max-h-[90vh] overflow-y-auto custom-scrollbar">
                    {loading ? (
                        <div className="flex items-center justify-center h-64">
                            <SpinningLoader />
                        </div>
                    ) : (
                        <div className="text-left">
                            <div className="divide-y">
                                <div className="px-6 py-4 flex items-center justify-between">
                                    <div>
                                        <h1 className="text-left tracking-wide font-semibold text-h1 text-grey-900">
                                            {subscriptionState === "subscribed" ? "Du hast keine Texte mehr übrig" : "Wähle den richtigen Plan für dich"}
                                        </h1>
                                        <h5 className="uppercase text-left text-grey-700 tracking-wide font-semibold text-xs">Upgrade dein Abonnement</h5>
                                    </div>
                                    <button onClick={onRequestClose} className="text-grey-600 hover:text-grey-800">
                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="p-6">
                                    {subscriptionState === "subscribed" ? (
                                        <div className="text-center">
                                            <p className="mb-4">Du hast bereits ein Abonnement, aber deine Texte sind aufgebraucht. Möchtest du dein Abonnement upgraden?</p>
                                            <button
                                                onClick={handleUpgrade}
                                                className="py-2 h-12 px-12 w-full rounded bg-secondary text-white text-lg hover:bg-secondary-600 relative flex items-center justify-center"
                                                disabled={isUpgrading}
                                            >
                                                {isUpgrading ? (
                                                    <>
                                                        <LoaderIcon className="animate-spin h-5 w-5 text-white mr-2" />
                                                        <span>Loading...</span>
                                                    </>
                                                ) : (
                                                    "Abonnement verwalten"
                                                )}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="p-6 grid gap-4 grid-cols-1 sm:grid-cols-2">
                                            <div className="col-span-1 sm:col-span-2">
                                                <p className="text-left text-grey-700 tracking-tighter font-normal text-sm">Upgrade deinen Plan, um mehr Texte zu generieren, wie 1.000+ unserer Kunden</p>
                                            </div>
                                            <div className="col-span-1 sm:col-span-2 flex items-center justify-start mb-4">
                                                <div className="relative inline-flex rounded-full bg-gray-200 p-1">
                                                    <button
                                                        onClick={() => setIsAnnual(false)}
                                                        className={`relative px-3 py-1 text-sm font-medium transition-colors duration-200 ease-in-out focus:outline-none text-gray-700`}
                                                    >
                                                        Monatlich
                                                    </button>
                                                    <button
                                                        onClick={() => setIsAnnual(true)}
                                                        className={`relative px-3 py-1 text-sm font-medium transition-colors duration-200 ease-in-out focus:outline-none text-gray-700`}
                                                    >
                                                        Jährlich (50% Rabatt)
                                                    </button>
                                                    <div
                                                        className={`absolute top-1 bottom-1 transition-all duration-200 ease-in-out ${isAnnual ? 'right-1 left-1/3' : 'left-1 right-2/3'
                                                            } bg-purple-400 bg-opacity-50 rounded-full`}
                                                    ></div>
                                                </div>
                                            </div>
                                            {/* Starter Plan */}
                                            <div className="border-2 p-4 rounded-lg flex flex-col relative self-stretch min-h-72 border-grey-200 bg-grey-100">
                                                <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-purple-500 text-white px-2 py-1 text-xs font-semibold rounded w-2/3 text-center">
                                                    AM BELIEBTESTEN
                                                </div>
                                                <h2 className="text-xl font-bold mb-2">Starter</h2>
                                                <div className="mb-4">
                                                    {renderPrice("Starter")}
                                                    <div className="text-sm text-gray-500">
                                                        pro Monat
                                                    </div>
                                                </div>
                                                <ul className="space-y-2 flex-grow text-sm">
                                                    <li className="flex items-center">
                                                        <svg className="w-4 h-4 mr-2 text-purple-500 hidden sm:block" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path>
                                                        </svg>
                                                        Bestes KI-Modell
                                                    </li>
                                                    <li className="flex items-center">
                                                        <svg className="w-4 h-4 mr-2 text-purple-500 hidden sm:block" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path>
                                                        </svg>
                                                        10 Personalisierte Vorlagen
                                                    </li>
                                                    <li className="flex items-center">
                                                        <svg className="w-4 h-4 mr-2 text-purple-500 hidden sm:block" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path>
                                                        </svg>
                                                        100 Texte / Monat
                                                    </li>
                                                </ul>
                                                <div className="mt-4 pt-4 border-t border-grey-200">
                                                    <button
                                                        onClick={() => upgradeUser(getPriceIdForPlan("Starter", isAnnual ? "year" : "month"))}
                                                        className="py-2 h-12 px-12 w-full rounded bg-secondary text-white text-lg hover:bg-secondary-600"
                                                    >
                                                        Upgrade
                                                    </button>
                                                </div>
                                            </div>
                                            {/* Creator Plan */}
                                            <div className="border-2 p-4 rounded-lg flex flex-col relative self-stretch min-h-72 border-grey-100">
                                                <h2 className="text-xl font-bold mb-2">Creator</h2>
                                                <div className="mb-4">
                                                    {renderPrice("Creator")}
                                                    <div className="text-sm text-gray-500">
                                                        {isAnnual ? `pro Monat` : `pro Monat`}
                                                    </div>
                                                </div>
                                                <ul className="space-y-2 flex-grow text-sm">
                                                    <li className="flex items-center">
                                                        <svg className="w-4 h-4 mr-2 text-purple-500 hidden sm:block" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path>
                                                        </svg>
                                                        Bestes KI-Modell
                                                    </li>
                                                    <li className="flex items-center">
                                                        <svg className="w-4 h-4 mr-2 text-purple-500 hidden sm:block" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path>
                                                        </svg>
                                                        100 Personalisierte Vorlagen
                                                    </li>
                                                    <li className="flex items-center">
                                                        <svg className="w-4 h-4 mr-2 text-purple-500 hidden sm:block" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path>
                                                        </svg>
                                                        Unlimitierte Texterstellung
                                                    </li>
                                                </ul>
                                                <div className="mt-4 pt-4 border-t border-grey-200">
                                                    <button
                                                        onClick={() => upgradeUser(getPriceIdForPlan("Creator", isAnnual ? "year" : "month"))}
                                                        className="py-2 h-12 px-12 w-full rounded bg-white text-secondary border-2 border-secondary hover:bg-secondary hover:text-white transition-colors duration-200 text-lg"
                                                    >
                                                        Upgrade
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
}

export default UpgradeModal;