import React from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle, CardDescription } from "../ui/card";
import { Button } from "../ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";

interface InputCardProps {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  onNext?: () => void;
  children: React.ReactNode;
  hasContent?: boolean;
  description?: string;
}

export const InputCard: React.FC<InputCardProps> = ({
  title,
  description,
  isExpanded,
  onToggle,
  onNext,
  children,
  hasContent = false,
}) => {
  return (
    <Card onClick={onToggle} className="cursor-pointer mt-4 bg-white">
      <CardHeader className="flex flex-col items-start justify-between">
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center">
            <div className={`w-3.5 h-3.5 rounded-full border border-dashed ${hasContent ? 'bg-green-100 border-green-500' : 'bg-khaki-600 border-khaki-600 '}`}></div>
            <CardTitle className="text-base font-medium ml-2 pl-2">{title}</CardTitle>
          </div>
          <div className="pointer-events-none">
            {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </div>
        </div>
        {isExpanded && (
          <CardDescription className="text-grey pt-4">{description}</CardDescription>
        )}
      </CardHeader>
      {isExpanded && (
        <>
          <CardContent onClick={(e) => e.stopPropagation()}>{children}</CardContent>
          <CardFooter className="flex justify-end" onClick={(e) => e.stopPropagation()}>
            {onNext && (
              <Button onClick={onNext} className="bg-slate-600 text-white">
                {"Weiter"}
              </Button>
            )}
          </CardFooter>
        </>
      )}
    </Card>
  );
};