import templates from "../../data/templates.json";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth } from "../../config/fbConfig";
import { useEffect, useState } from "react";

// Modular Fireabse SDK imports
import { getUserTemplates } from "../../utils/userUtils";
import TemplateCard from "../../components/cards/TemplateCard";
import LayoutInSide from "../../layouts/LayoutInSide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const CategoryPage = () => {
    // State Variables
    const [allTemplates, setAllTemplates] = useState(templates);

    // Providers
    const navigate = useNavigate();
    const location = useLocation();
    const paths = location.pathname.split('/');
    const category = decodeURIComponent(paths[3])

    // Functions & Event Handlers
    const loadUserTemplates = async () => {
        if (!auth.currentUser) {
            navigate("/sign-in");
            return;
        }
        let userTemplates = await getUserTemplates(auth.currentUser!.uid);
        let templates_out = [...userTemplates, ...templates];
        setAllTemplates(templates_out);

    };

    /* UseEffect Hooks */

    useEffect(() => {
        loadUserTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LayoutInSide>
            <div className="flex justify-between mb-4">
                <h3 className="pr-2 text-xl font-semibold font-Outfit whitespace-nowrap">{category}</h3>
                <Link to="/templates" className="text-[#AAA8BD] text-xs">   <FontAwesomeIcon icon={faArrowLeft}
                    className="pl-2 group-hover:text-white transition-colors duration-200 me-2" />
                    Zu allen Vorlagen</Link>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
                {allTemplates
                    .filter((curtemplate) => curtemplate.type.toLowerCase() === category?.toLowerCase())
                    .map((curtemplate, index) => (
                        <div key={index} className="h-full">
                            <TemplateCard items={curtemplate} pinIconDisabled={true} />
                        </div>
                    ))
                }
            </div>
        </LayoutInSide>
    )
};

export default CategoryPage;