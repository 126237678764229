import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DocumentEditor } from '../../components/editor/DocumentEditor';
import LayoutInSide from '../../layouts/LayoutInSide';
import { auth } from '../../config/fbConfig';
import { DocumentManager } from '../../utils/documents/DocumentManager';

const DocumentEditPage = () => {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [initialContent, setInitialContent] = useState('');
  const [initialTitle, setInitialTitle] = useState('');

  useEffect(() => {
    const loadDocument = async () => {
      if (!documentId || !auth.currentUser) {
        navigate('/documents');
        return;
      }

      const doc = await DocumentManager.loadById(auth.currentUser.uid, documentId);
      if (!doc) {
        navigate('/documents');
        return;
      }

      setInitialContent(doc.getRichText());
      setInitialTitle(doc.getTitle());
    };

    loadDocument();
  }, [documentId, navigate]);

  return (
    <LayoutInSide>
      <div className="flex flex-col h-[calc(100vh-64px)] md:h-full max-w-[1200px] mx-auto md:px-4">
        <div className="flex-grow h-full">
          <DocumentEditor
            documentId={documentId}
            userId={auth.currentUser?.uid || ''}
            initialContent={initialContent}
            initialTitle={initialTitle}
          />
        </div>
      </div>
    </LayoutInSide>
  );
};

export default DocumentEditPage; 