import React from "react";

type Props = {
  children: React.ReactNode;
};

const Container = (props: Props) => {
  return (
    <div
      className={`container mx-auto h-full p-2 sm:p-4 lg:p-6 xl:p-8`}
    >
      {props.children}
    </div>
  );
};

export default Container;