import { Button } from "./button";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export function Pagination({ currentPage, totalPages, onPageChange }: PaginationProps) {
  return (
    <div className="border-t border-gray-200">
      <div className="flex items-center justify-center gap-2 py-4">
        <Button
          variant="outline"
          size="icon"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="h-10 w-10"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        
        <div className="flex items-center gap-2">
          <Button
            variant={currentPage === 1 ? "secondary" : "outline"}
            className="h-10 w-10"
            onClick={() => onPageChange(1)}
          >
            1
          </Button>
          
          {currentPage > 3 && <span className="px-2">...</span>}
          
          {currentPage > 2 && (
            <Button
              variant="outline"
              className="h-10 w-10"
              onClick={() => onPageChange(currentPage - 1)}
            >
              {currentPage - 1}
            </Button>
          )}
          
          {currentPage !== 1 && currentPage !== totalPages && (
            <Button
              variant="secondary"
              className="h-10 w-10"
            >
              {currentPage}
            </Button>
          )}
          
          {currentPage < totalPages - 1 && (
            <Button
              variant="outline"
              className="h-10 w-10"
              onClick={() => onPageChange(currentPage + 1)}
            >
              {currentPage + 1}
            </Button>
          )}
          
          {currentPage < totalPages - 2 && <span className="px-2">...</span>}
          
          {totalPages > 1 && (
            <Button
              variant={currentPage === totalPages ? "secondary" : "outline"}
              className="h-10 w-10"
              onClick={() => onPageChange(totalPages)}
            >
              {totalPages}
            </Button>
          )}
        </div>

        <Button
          variant="outline"
          size="icon"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="h-10 w-10"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
} 