import { 
  doc, 
  getDoc, 
  setDoc, 
  collection, 
  serverTimestamp,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  DocumentSnapshot,
  QueryDocumentSnapshot
} from 'firebase/firestore';
import { firestore } from '../../config/fbConfig';
import debounce from 'lodash/debounce';

export interface DocumentData {
  id: string;
  title: string;
  richText: string;
  createdAt: Date;
  updatedAt: Date;
  originTemplate: string | null;
  status: 'Entwurf' | 'Fertig' | 'Vorlage';
  isFavorite?: boolean;
}

export class DocumentManager {
  private data: DocumentData;
  private userId: string;
  private isSaving: boolean = false;

  private static debouncedSaveCallbacks: { [key: string]: ReturnType<typeof debounce> } = {};
  private static documentsBeingCreated = new Set<string>();

  private constructor(data: DocumentData, userId: string) {
    this.data = data;
    this.userId = userId;
  }

  // Replace getters with methods
  getId(): string { return this.data.id; }
  getTitle(): string { return this.data.title; }
  getRichText(): string { return this.data.richText; }
  getCreatedAt(): Date { return this.data.createdAt; }
  getUpdatedAt(): Date { return this.data.updatedAt; }
  getOriginTemplate(): string | null { return this.data.originTemplate || null; }

  // Update setters to use method names
  async setTitle(newTitle: string): Promise<DocumentManager> {
    if (newTitle === this.data.title) return this;
    
    this.data.title = newTitle;
    this.data.updatedAt = new Date();
    await this.save(newTitle);
    return this;
  }

  setRichText(richText: string): DocumentManager {
    this.data.richText = richText;
    this.save(undefined, richText);
    return this;
  }

  async setStatus(status: DocumentData['status']): Promise<DocumentManager> {
    if (status === this.data.status) return this;
    
    await this.save(undefined, undefined, { status });
    return this;
  }

  async setFavorite(isFavorite: boolean): Promise<DocumentManager> {
    if (isFavorite === this.data.isFavorite) return this;
    
    this.data.isFavorite = isFavorite;
    this.data.updatedAt = new Date();
    
    // Save the favorite status explicitly
    await this.save(undefined, undefined, { isFavorite });
    return this;
  }

  // Helper to check if document should be saved
  private static shouldSaveDocument(title: string, richText: string): boolean {
    return !(title === 'Unbenanntes Dokument' && (!richText || richText.trim() === ''));
  }

  // Create a new document in Firebase
  static async create(userId: string, initialData?: Partial<DocumentData>): Promise<DocumentManager> {
    const docRef = doc(collection(firestore, `users/${userId}/documents`));
    const documentKey = `${userId}_${docRef.id}`;
    
    // Check if document is already being created
    if (this.documentsBeingCreated.has(documentKey)) {
      throw new Error('Document creation already in progress');
    }
    
    try {
      this.documentsBeingCreated.add(documentKey);
      
      const now = new Date();
      const documentData: DocumentData = {
        id: docRef.id,
        title: initialData?.title || 'Unbenanntes Dokument',
        richText: initialData?.richText || '',
        createdAt: now,
        updatedAt: now,
        originTemplate: initialData?.originTemplate || null,
        status: initialData?.status || 'Entwurf'
      };

      // Only save to Firebase if document has content or custom title
      if (this.shouldSaveDocument(documentData.title, documentData.richText)) {
        const firestoreData = {
          ...documentData,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };
        
        // Clean undefined values
        (Object.keys(firestoreData) as Array<keyof typeof firestoreData>).forEach(key => {
          if (firestoreData[key] === undefined) {
            delete firestoreData[key];
          }
        });

        await setDoc(docRef, firestoreData);
      }

      return new DocumentManager(documentData, userId);
    } finally {
      this.documentsBeingCreated.delete(documentKey);
    }
  }

  // Load an existing document from Firebase
  static async loadById(userId: string, documentId: string): Promise<DocumentManager | null> {
    const docRef = doc(firestore, `users/${userId}/documents/${documentId}`);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return null;
    }

    return DocumentManager.fromSnapshot(docSnap, userId);
  }

  // Save changes to Firebase
  async save(title?: string, richText?: string, updates: Partial<DocumentData> = {}): Promise<void> {
    if (this.isSaving) return;
    
    this.isSaving = true;
    
    try {
      const docRef = doc(firestore, `users/${this.userId}/documents/${this.data.id}`);
      
      const saveUpdates: Partial<DocumentData> = {
        updatedAt: new Date(),
        ...updates // Include any additional updates
      };

      if (title !== undefined) {
        saveUpdates.title = title;
        this.data.title = title;
      }

      if (richText !== undefined) {
        saveUpdates.richText = richText;
        this.data.richText = richText;
      }

      // Only save if there are actual changes
      if (Object.keys(saveUpdates).length > 1) { // > 1 because updatedAt is always included
        await setDoc(docRef, {
          ...saveUpdates,
          updatedAt: serverTimestamp()
        }, { merge: true });
      }
    } finally {
      this.isSaving = false;
    }
  }

  // Delete document from Firebase
  async delete(): Promise<void> {
    const docRef = doc(firestore, `users/${this.userId}/documents/${this.data.id}`);
    await deleteDoc(docRef);
  }

  // Get all documents for a user, ordered by last update
  static async getAllForUser(userId: string): Promise<DocumentManager[]> {
    const docsRef = collection(firestore, `users/${userId}/documents`);
    const q = query(docsRef, orderBy('updatedAt', 'desc'));
    const querySnapshot = await getDocs(q);
    
    return querySnapshot.docs.map(doc => DocumentManager.fromSnapshot(doc, userId));
  }

  // Helper method to convert Firebase snapshot to DocumentManager instance
  private static fromSnapshot(snapshot: DocumentSnapshot | QueryDocumentSnapshot, userId: string): DocumentManager {
    const data = snapshot.data();
    if (!data) {
      throw new Error(`No data found for document ${snapshot.id}`);
    }

    return new DocumentManager({
      id: snapshot.id,
      title: data.title,
      richText: data.richText,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
      originTemplate: data.originTemplate || null,
      status: data.status,
      isFavorite: data.isFavorite || false
    }, userId);
  }

  // Utility method to convert to plain object
  toJSON(): DocumentData {
    return { ...this.data };
  }

  // Add a method to trigger debounced save
  async saveDebounced(title?: string, richText?: string): Promise<void> {
    return this.save(title, richText);
  }

  // Add cleanup method
  static clearDebouncedCallbacks() {
    Object.values(this.debouncedSaveCallbacks).forEach(callback => callback.cancel());
    this.debouncedSaveCallbacks = {};
  }

  async duplicate(): Promise<DocumentManager> {
    const titleRegex = /^(.+?)(?:\s*\((\d+)\))?$/;
    const match = this.data.title.match(titleRegex);
    const baseTitle = match![1];

    // Get all documents to check for existing duplicates
    const allDocs = await DocumentManager.getAllForUser(this.userId);
    const duplicateNumbers = allDocs
      .map(doc => {
        const match = doc.getTitle().match(new RegExp(`^${baseTitle}\\s*\\((\\d+)\\)$`));
        return match ? parseInt(match[1]) : 0;
      })
      .filter(num => num > 0);

    const nextNumber = duplicateNumbers.length > 0 ? Math.max(...duplicateNumbers) + 1 : 1;
    const newTitle = `${baseTitle} (${nextNumber})`;

    return DocumentManager.create(this.userId, {
      ...this.data,
      title: newTitle,
      id: undefined as any, // Let Firestore generate a new ID
      createdAt: new Date(),
      updatedAt: new Date()
    });
  }

  // Add method to get status
  getStatus(): DocumentData['status'] {
    return this.data.status;
  }
} 