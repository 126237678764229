import Container from "../components/Container";

const NavbarOutSide = ({ isShadow = true }: { isShadow?: boolean }) => {
  return (
    <header className={`${!isShadow && 'radial-bg'}`}>
      <Container>
        <div className="relative">
          <span className={`absolute top-0 right-0  ${isShadow ? "shadow-blue-overlay" : ""}`} />
          <nav className="flex items-center py-6 relative">
            <div className="mr-auto">
              <div className="block h-10">
                <img
                  className="block ml-4 h-full w-full"
                  src="/assets/images/logo.svg"
                  alt="logo"
                />
              </div>
            </div>
          </nav>
        </div>
      </Container>
    </header>
  );
};

export default NavbarOutSide;
