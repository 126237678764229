import React, { useState, useEffect } from 'react';
import { UserData } from '../../utils/userUtils';
import { useManageStripeSubscription } from '../../utils/ManageStripeSubscription';
import MiniSpinningLoader from "../loader/mini_spinning_loader";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '~/config/fbConfig';

interface UpgradeBannerProps {
  userData: UserData;
}

const UpgradeBanner: React.FC<UpgradeBannerProps> = ({ userData }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState('00:00:00');
  const [loading, setLoading] = useState(false);
  const [subscriptionEnd, setSubscriptionEnd] = useState<Date | null>(null);
  const manageStripeSubscription = useManageStripeSubscription();

  // Check if banner should be visible
  const shouldShowBanner = () => {
    const user = userData.userData;
    return (user.creations <= 0 && !user.unlimited && user.bonus_creations <= 0);
  };

  // Load subscription data once if needed
  useEffect(() => {
    const loadSubscriptionData = async () => {
      // Only load subscription if user is not on free trial and not unlimited
      if (!userData.userData.freetrial && !userData.userData.unlimited) {
        const subscriptionsRef = collection(firestore, "users", userData.userData.userId!, "subscriptions");
        const q = query(subscriptionsRef, where("status", "==", "active"));
        const subscriptionSnap = await getDocs(q);
        
        if (!subscriptionSnap.empty) {
          const subData = subscriptionSnap.docs[0].data();
          setSubscriptionEnd(subData.current_period_end.toDate());
        }
      }
    };

    loadSubscriptionData();
  }, [userData.userData.uid, userData.userData.freetrial, userData.userData.unlimited]);

  // Handle banner visibility
  useEffect(() => {
    const bannerClosed = localStorage.getItem('bannerClosed');
    const bannerClosedTime = localStorage.getItem('bannerClosedTime');
    const now = new Date().getTime();

    if (bannerClosed === 'true' && bannerClosedTime) {
      const timeDiff = now - parseInt(bannerClosedTime);
      const hoursDiff = timeDiff / (1000 * 60 * 60);
      
      if (hoursDiff >= 24) {
        localStorage.removeItem('bannerClosed');
        localStorage.removeItem('bannerClosedTime');
        setIsVisible(shouldShowBanner());
      } else {
        setIsVisible(false);
      }
    } else {
      setIsVisible(shouldShowBanner());
    }
  }, [userData]);

  // Update time left
  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const user = userData.userData;

      if (user.freetrial) {
        // Calculate time until midnight
        const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        const difference = midnight.getTime() - now.getTime();

        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      } else if (!user.unlimited && subscriptionEnd) {
        // Calculate time until subscription ends
        const difference = subscriptionEnd.getTime() - now.getTime();

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return `${days} Tagen ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }

      return '00:00:00';
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [userData.userData, subscriptionEnd]);

  // Rest of the component remains the same...
  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem('bannerClosed', 'true');
  };

  const handleUpgrade = () => {
    setLoading(true);
    manageStripeSubscription();
  };

  if (!isVisible) return null;

  return (
    <div className="bg-purple-500 text-white p-4 flex items-center justify-between mb-4">
      <div className="flex-1 flex items-center justify-center">
        <span className="mr-2">
          {userData.userData.freetrial ? "Kostenlose Texte wieder verfügbar in " : "Neue Texte wieder verfügbar in "}
          {timeLeft === '00:00:00' ? (
            <div className="inline-block ml-2 h-4 w-4">
              <MiniSpinningLoader />
            </div>
          ) : (
            timeLeft
          )}.
        </span>
        <button
          onClick={handleUpgrade}
          className="bg-white text-purple-500 px-3 py-1 rounded-md font-semibold hover:bg-purple-100 transition-colors mx-2 flex items-center"
          disabled={loading}
        >
          {loading ? (
            <>
              <span>Upgrade</span>
              <div className="ml-2 h-4 w-4">
                <MiniSpinningLoader />
              </div>
            </>
          ) : (
            'Upgrade'
          )}
        </button>
        <span>oder <a href="/reward-center" className="underline">hier mehr Texte erhalten</a>.</span>
      </div>
      <button onClick={handleClose} className="text-white hover:text-purple-200 ml-4">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  );
};

export default UpgradeBanner;
