// TextArea.tsx
import React, { forwardRef } from 'react';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, ...props }, ref) => {
    return (
      <textarea ref={ref} aria-label={label} {...props} />
    );
  }
);

TextArea.displayName = 'TextArea';

interface SingleLineInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const SingleLineInput = forwardRef<HTMLInputElement, SingleLineInputProps>(
  ({ label, ...props }, ref) => {
    return (
      <input ref={ref} aria-label={label} {...props} />
    );
  }
);

SingleLineInput.displayName = 'SingleLineInput'
