import React, { useEffect, useState } from 'react';
import LayoutInSide from '../../layouts/LayoutInSide';
import { Input } from '../../components/ui/input';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '../../components/ui/table';
import { Button } from '../../components/ui/button';
import { MoreHorizontal } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { DocumentManager, DocumentData } from '../../utils/documents/DocumentManager';
import { auth, firestore } from '../../config/fbConfig';
import { formatDistanceToNow } from 'date-fns';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog";
import { Pagination } from "../../components/ui/pagination";
import { useManageStripeSubscription } from "../../utils/ManageStripeSubscription";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../components/ui/card";
import { doc, getDoc } from "firebase/firestore";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components/ui/dialog";

const DocumentsPage = () => {
  const [documents, setDocuments] = useState<DocumentData[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const documentsPerPage = 10;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);
  const manageStripeSubscription = useManageStripeSubscription();
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [documentToRename, setDocumentToRename] = useState<string | null>(null);
  const [newTitle, setNewTitle] = useState('');

  useEffect(() => {
    const fetchDocuments = async () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        const docs = await DocumentManager.getAllForUser(userId);
        setDocuments(docs.map(docManager => docManager.toJSON()));
      } else {
        navigate('/sign-in');
      }
    };
    fetchDocuments();
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(firestore, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          setIsFreeTrial(userDoc.data().freetrial === true);
        }
      }
    };

    fetchUserData();
  }, []);

  // Filter documents based on search query
  const filteredDocuments = documents.filter(doc =>
    doc?.title?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastDoc = currentPage * documentsPerPage;
  const indexOfFirstDoc = indexOfLastDoc - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(indexOfFirstDoc, indexOfLastDoc);

  const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage);

  const handleStatusChange = async (docId: string, newStatus: string) => {
    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, docId);
      if (doc) {
        await doc.setStatus(newStatus as DocumentData['status']);
        // Refresh documents list
        const updatedDocs = await DocumentManager.getAllForUser(auth.currentUser!.uid);
        setDocuments(updatedDocs.map(doc => doc.toJSON()));
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDelete = async () => {
    if (!documentToDelete) return;
    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, documentToDelete);
      if (doc) {
        await doc.delete();
        setDocuments(prev => prev.filter(d => d.id !== documentToDelete));
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
    setDocumentToDelete(null);
    setIsDeleteDialogOpen(false);
  };

  const handleFavorite = async (docId: string, currentFavorite: boolean) => {
    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, docId);
      if (doc) {
        // First set the favorite status
        await doc.setFavorite(!currentFavorite);
        
        // Refresh documents list after successful save
        const updatedDocs = await DocumentManager.getAllForUser(auth.currentUser!.uid);
        setDocuments(updatedDocs.map(doc => doc.toJSON()));
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  };

  const handleDuplicate = async (docId: string) => {
    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, docId);
      if (doc) {
        await doc.duplicate();
        // Refresh documents list
        const updatedDocs = await DocumentManager.getAllForUser(auth.currentUser!.uid);
        setDocuments(updatedDocs.map(doc => doc.toJSON()));
      }
    } catch (error) {
      console.error('Error duplicating document:', error);
    }
  };

  const handleRename = async () => {
    if (!documentToRename || !newTitle.trim()) return;

    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, documentToRename);
      if (doc) {
        // First set the title
        await doc.setTitle(newTitle.trim());
        // Then explicitly call save with the new title
        await doc.save(newTitle.trim());
        
        // Refresh documents list after successful save
        const updatedDocs = await DocumentManager.getAllForUser(auth.currentUser!.uid);
        setDocuments(updatedDocs.map(doc => doc.toJSON()));
      }
    } catch (error) {
      console.error('Error renaming document:', error);
    }
    
    setIsRenameDialogOpen(false);
    setDocumentToRename(null);
    setNewTitle('');
    
    // Add small delay to reset pointer events
    setTimeout(() => {
      document.body.style.pointerEvents = '';
    }, 100);
  };

  // In the render method, update the document title display:
  const renderDocumentTitle = (doc: DocumentData) => (
    <span>
      {doc.isFavorite && '⭐ '}
      {doc.title}
    </span>
  );

  return (
    <LayoutInSide>
      <div className="flex flex-col min-h-full">
        {/* Page header */}
        <div className="p-4">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <h1 className="text-2xl font-semibold">Verlauf</h1>
            {/* Search bar */}
            <div className="hidden md:block w-full md:w-1/3 mt-2 md:mt-0">
              <Input
                placeholder="Suchen..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                className="w-full"
              />
            </div>
          </div>
        </div>

        {/* Table section */}
        <div className="p-4 flex-grow relative">
          <div className="relative border border-gray-200 rounded-lg overflow-hidden">
            <Table>
              <TableHeader className="bg-[#baa5e0]">
                <TableRow>
                  <TableHead className="font-medium">Name</TableHead>
                  {/* Hide these columns on mobile */}
                  <TableHead className="hidden md:table-cell font-medium">Beschreibung</TableHead>
                  <TableHead className="table-cell font-medium">Zuletzt bearbeitet</TableHead>
                  <TableHead className="hidden md:table-cell font-medium">Status</TableHead>
                  <TableHead className="font-medium"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="bg-white">
                {currentDocuments.map((doc, index) => (
                  <React.Fragment key={doc.id}>
                    <TableRow
                      className={`
                        ${isFreeTrial && (currentPage > 1 || index >= 3) ? 'blur-sm pointer-events-none' : ''}
                      `}
                    >
                      <TableCell>
                        <button
                          onClick={() => navigate(`/documents/${doc.id}`)}
                          className="text-left hover:text-purple-600 hover:underline"
                          disabled={isFreeTrial && (currentPage > 1 || index >= 3)}
                        >
                          {renderDocumentTitle(doc)}
                        </button>
                      </TableCell>
                      {/* Hide these cells on mobile */}
                      <TableCell className="hidden md:table-cell">
                        {doc.richText ? doc.richText.replace(/<[^>]*>/g, '').substring(0, 50) + '...' : 'Keine Beschreibung'}
                      </TableCell>
                      <TableCell className="table-cell">
                        {formatDistanceToNow(doc.updatedAt, { addSuffix: true, locale: undefined })}
                      </TableCell>
                      <TableCell className="hidden md:table-cell">
                        <Select
                          defaultValue={doc.status}
                          onValueChange={(value: string) => {
                            handleStatusChange(doc.id, value);
                          }}
                        >
                          <SelectTrigger className="w-[140px] bg-white">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent className="bg-white">
                            <SelectItem
                              value="Entwurf"
                              className="hover:bg-purple-50 cursor-pointer"
                            >
                              Entwurf
                            </SelectItem>
                            <SelectItem
                              value="Fertig"
                              className="hover:bg-purple-50 cursor-pointer"
                            >
                              Fertig
                            </SelectItem>
                            <SelectItem
                              value="Vorlage"
                              className="hover:bg-purple-50 cursor-pointer"
                            >
                              Vorlage
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger className="hover:bg-gray-100 p-2 rounded-md bg-[#F7F7F7]">
                            <MoreHorizontal className="h-4 w-4" />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="bg-white" align="end">
                            <DropdownMenuItem
                              className="hover:bg-purple-50 cursor-pointer"
                              onClick={() => handleFavorite(doc.id, doc.isFavorite || false)}
                            >
                              {doc.isFavorite ? 'Unfavorisieren' : 'Favorisieren'}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="hover:bg-purple-50 cursor-pointer"
                              onClick={() => {
                                setDocumentToRename(doc.id);
                                setNewTitle(doc.title);
                                setIsRenameDialogOpen(true);
                              }}
                            >
                              Umbenennen
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="hover:bg-purple-50 cursor-pointer"
                              onClick={() => handleDuplicate(doc.id)}
                            >
                              Duplizieren
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              className="text-red-600 hover:bg-red-50 hover:text-red-700 cursor-pointer"
                              onClick={() => {
                                setDocumentToDelete(doc.id);
                                setIsDeleteDialogOpen(true);
                              }}
                            >
                              Löschen
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                    {isFreeTrial && currentPage === 1 && index === 2 && documents.length > 3 && (
                      <div className="absolute left-0 right-0 top-[280px] flex items-center justify-center pointer-events-none">
                        <img
                          src="/assets/images/padlock.png"
                          alt="Locked"
                          className="w-12 h-12"
                        />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>

          {isFreeTrial && (documents.length > 3 || currentPage > 1) && (
            <Card className="fixed bottom-8 left-1/2 md:ml-[105px] transform -translate-x-1/2 max-w-lg w-[90%] bg-white shadow-lg mb-[30vh]">
              <CardHeader className="text-center">
                <CardTitle className="text-center font-bold text-lg">
                  Ausgeblendete Texte in deinem Verlauf freischalten
                </CardTitle>
                <CardDescription className="text-center">
                  Sobald Dein Verlauf drei Einträge überschreitet, wird der Rest ausgeblendet. Verwende unsere Premium-Version, um unbegrenzten Zugang zu erhalten.
                </CardDescription>
              </CardHeader>
              <CardContent className="text-center">
                <Button
                  onClick={() => manageStripeSubscription()}
                  className="bg-secondary hover:bg-secondary-600 text-white"
                >
                  Upgrade
                </Button>
              </CardContent>
            </Card>
          )}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="mt-auto">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </div>

      {/* Delete Dialog */}
      {isDeleteDialogOpen && (
        <AlertDialog open={isDeleteDialogOpen} onOpenChange={(open) => {
          setIsDeleteDialogOpen(open)
          setTimeout(() => {
            if (!open) {
              document.body.style.pointerEvents = ''
            }
          }, 100)
          }}>
          <AlertDialogContent className="bg-white">
            <AlertDialogHeader>
              <AlertDialogTitle>Bist Du sicher?</AlertDialogTitle>
              <AlertDialogDescription>
                Diese Aktion kann nicht rückgängig gemacht werden. Dies wird dein Dokument dauerhaft löschen.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => setIsDeleteDialogOpen(false)}>Abbrechen</AlertDialogCancel>
              <AlertDialogAction
                onClick={handleDelete}
                className="bg-red-600 hover:bg-red-700"
              >
                Löschen
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}

      {/* Rename Dialog */}
      {isRenameDialogOpen && (
        <Dialog open={isRenameDialogOpen} onOpenChange={(open) => {
          setIsRenameDialogOpen(open)
          setTimeout(() => {
            if (!open) {
              document.body.style.pointerEvents = ''
            }
          }, 100)
          }}>
          <DialogContent className="bg-white">
            <DialogHeader>
              <DialogTitle>Dokument umbenennen</DialogTitle>
            </DialogHeader>
            <div className="py-4">
              <Input
                value={newTitle}
                onChange={(e) => {
                  setNewTitle(e.target.value)
                }}
                placeholder="Neuer Titel"
                className="w-full"
              />
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsRenameDialogOpen(false)}>
                Abbrechen
              </Button>
              <Button
                onClick={handleRename}
                className="bg-secondary hover:bg-secondary-600 text-white"
              >
                Umbenennen
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </LayoutInSide>
  );
};

export default DocumentsPage; 