import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import TemplateCard from "../../components/cards/TemplateCard";
import templates from "../../data/templates.json";
import { useLocation } from "react-router-dom";
import LayoutInSide from "../../layouts/LayoutInSide";
import { useNavigate } from "react-router-dom";
import '../../types/globals.d'
import firebase, { doc, onSnapshot, getDoc, firestore, auth, functions } from "../../config/fbConfig";
import SpinningLoader from "../../components/loader/spinning_loader";
import { getUserTemplates } from "../../utils/userUtils";
import { usePostHog } from 'posthog-js/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from "swiper";
import 'swiper/css';
import GenericModal from '../../layouts/Modal/GenericModal';
import { Reorder, motion } from 'framer-motion'
import { DragandDropIcon, CollapseArrowDOWN, CollapseArrowUP } from "../../assets/Icons";
import { httpsCallable } from "firebase/functions";

// Modular Fireabse SDK imports
import { collection, setDoc, updateDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useModal } from "../../providers/ModalProvider";
import { ModalTypeEnum } from "../../consts";
import { useShareableData } from "../../providers/ShareableDataProvider";

interface ITag {
    type: string;
    collapsed: boolean;
}

const tagsconst = [
    { type: "alle", collapsed: false },
    { type: "Persönliche Vorlagen", collapsed: false },
    { type: "Allgemein", collapsed: false },
    { type: "Social media", collapsed: false },
    { type: "Email", collapsed: false },
    { type: "Blog", collapsed: false },
    { type: "Ads", collapsed: false },
];

const FreiFeldItems = {
    name: "Freifeld-Eingabe",
    prompt: "{text}",
    placeholders: [
        {
            label: "Text",
            type: "textarea"
        }
    ],
    replaceValues: [
        "text"
    ],
    type: "Allgemein",
    iconName: "fas fa-pencil-alt",
    iconColor: "#9C68EF",
    title: "Freifeld-Eingabe",
    description: "Nutzen Sie diese Vorlage und beschreiben Sie ganz genau, was Sie sich wünschen!"
}

const TemplatesPage = () => {
    // Providers
    const location = useLocation();
    const templateDetail = useLocation();
    const navigate = useNavigate();
    const posthog = usePostHog();
    const { setActiveModal } = useModal();
    const { showOnBoarding } = useShareableData()

    // State Variables
    const [query, setQuery] = useState("");
    const [tags, setTags] = useState<ITag[]>(tagsconst);
    const isFromDynamicTemplatePage = templateDetail.state?.showDynTemplatePopUp || false;
    const isFromDynamicEditPage = templateDetail.state?.showEditTemplatePopUp || false;
    const isFromVerifyPage = location.pathname === "/welcome";
    const isFromSuccessPage = location.pathname === "/success";
    const [showWelcomePopup] = useState(isFromVerifyPage);
    const [loadingTemplates, setLoadingTemplates] = useState(true);
    const [allTemplates, setAllTemplates] = useState(templates);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showDynamicTemplatePopup, setShowDynamicTemplatePopup] = useState(isFromDynamicTemplatePage || false);
    const [showDynamicEditPopup, setShowDynamicEditPopup] = useState(isFromDynamicEditPage || false);
    const [draggable, setDraggable] = useState(false);

    // Functions & Event Handlers

    const loadUserTemplates = async () => {
        // This fallback is needed, otherwise, when a user logs out and then immediately hits the back button
        // this function would try to run with a null user, causing an uncaught error
        if (!auth.currentUser) {
            navigate("/sign-in");
            return;
        }

        const userTemplatesRef = firebase.firestore().collection('users').doc(auth.currentUser!.uid).collection('templates');

        const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');

        let userTemplates = await getUserTemplates(auth.currentUser!.uid);

        // Prepending the user templates to the templates from JSON
        let templates_out = [...userTemplates, ...templates];
        setAllTemplates(templates_out);
        // // Extract the tags
        // const tagsFromTemplates = Array.from(new Set(templates_out.map(template => template.type)));
        // setTags(tagsFromTemplates.map(tag => ({type: tag})));
        onSnapshot(writeDataDocRef, processTemplates);
        // The function passed into onSnapshot will execute every time the data changes
        userTemplatesRef.onSnapshot(async (snapshot) => {
            setLoadingTemplates(true)
            const docSnap = await getDoc(writeDataDocRef);
            processTemplates(docSnap);
            if (!tags.some(tag => tag.type === "Persönliche Vorlagen")) {
                let new_tag = {
                    type: "Persönliche Vorlagen",
                    collapsed: false
                };
                setTags([new_tag, ...tags]);
                await updateDatabase([new_tag, ...tags]);
            }

        });
    };

    const processTemplates = async (docSnap: any) => {
        let userTemplates = await getUserTemplates(auth.currentUser!.uid);

        // Prepending the user templates to the templates from JSON
        let templates_out = [...userTemplates, ...templates];
        if (docSnap.exists()) {


            const data = docSnap.data();
            let templatesOrder = data.pinned_templates || [];

            let updatedTemplates = templates_out.map(template_idx => {
                if (templatesOrder.includes(template_idx.id)) {
                    return { ...template_idx, favorite: true }
                } else {
                    return { ...template_idx, favorite: false };
                }
            });
            templates_out = updatedTemplates;

            templates_out.sort((a, b) => {
                let aIsInTemplates = templatesOrder.includes(a.id);
                let bIsInTemplates = templatesOrder.includes(b.id);

                if (aIsInTemplates && bIsInTemplates) {
                    return 0;
                } else if (aIsInTemplates) {
                    return -1;
                } else if (bIsInTemplates) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        setAllTemplates(templates_out);
        setLoadingTemplates(false);
    }

    /**
     * Loads the price for a given price ID from the Firebase database.
     * @param priceId The ID of the price to load.
     * @returns The price value, or null if the price is not found.
     */
    const loadPriceForID = async (priceId: string): Promise<number | null> => {
        try {
            // Query the Firestore database for active products
            const snapshot = await firebase
                .firestore()
                .collection("products")
                .where("active", "==", true)
                .get();

            // Loop through the product documents and query the prices subcollection for the given price ID
            for (const productDoc of snapshot.docs) {
                const priceSnapShot = await productDoc.ref.collection("prices").doc(priceId).get();

                // If the price document exists, return the price as a number
                if (priceSnapShot.exists) {
                    const price = priceSnapShot.data()?.unit_amount;
                    return price ? price / 100 : null;
                }
            }

            // If the price could not be found, return null
            return null;
        } catch (error) {
            // If an error occurs, log the error and return null
            console.error(error);
            return null;
        }
    };

    /**
  * This function handles subscription tracking by sending conversion events
  * to Google Tag Manager, Facebook Pixel, and other tracking systems.
  * It checks the `sessionStorage` to prevent the tag from firing multiple times.
  */
    const handleSubscriptionTracking = async () => {
        // Check the storage item to prevent the tag from firing multiple times
        const hasRun = sessionStorage.getItem("subscriptionTracking");

        // If the tag has not run and the user is coming from the success page, send the conversion event
        if (!hasRun && isFromSuccessPage) {


            // If the page is not fully loaded, wait for it to load
            if (document.readyState !== 'complete') {
                window.addEventListener('load', () => {
                    handleSubscriptionTracking();
                });
                return;
            }

            // Load the price
            const params = new URLSearchParams(location.search);
            const priceId = params.get("priceId");

            // If the price ID is present in the URL parameters, load the price and send the conversion event
            const price = priceId ? await loadPriceForID(priceId) : 29;
            try {
                // Needs to fire before the actual event code, otherwise the attribute is not set
                // ref: https://support.google.com/google-ads/answer/13262500
                (window as any).dataLayer.push({
                    'email_address': auth.currentUser?.email,
                });

                // Google Enhanced Conversion Tracking Code
                // Needs to fire before the actual event code, otherwise the attribute is not set
                // ref: https://support.google.com/google-ads/answer/13262500
                if (typeof window !== 'undefined' && (window as any).dataLayer) {
                    (window as any).dataLayer.push({
                        'email_address': auth.currentUser?.email,
                    });
                }

                // Google Tag Manager code for conversion tracking
                (window as any).dataLayer.push({
                    'event': 'SubscriptionCreated',
                    'value': price,
                    'currency': 'EUR'
                });

                // Bing UET tag for conversion tracking
                (window as any).uetq.push(
                    'event', 'SubscriptionCreated', {
                    "revenue_value": price,
                    "currency": "EUR"
                });

                // Facebook Pixel Purchase event tracking
                if (typeof window !== 'undefined' && (window as any).fbq) {
                    (window as any).fbq('track', 'Purchase', {
                        'value': price,
                        'currency': 'EUR',
                    });
                }

                // Set the storage item to prevent the tag from firing multiple times
                sessionStorage.setItem("subscriptionTracking", "true");

            } catch (error) {
                console.error(error);
            }

            // Posthog event success_page:pageview
            posthog.capture('success_page:pageview', {
                price_id: priceId,
                price: price
            });
        }
    };

    const handleSignUpTracking = () => {	
        // Check if the tracking has already been done	
        const hasRun = sessionStorage.getItem("leadTracking");	

        // If the page is not fully loaded, wait for it to load	
        if (document.readyState !== 'complete') {	
            window.addEventListener('load', () => {	
                handleSignUpTracking();	
            });	
            return;	
        }

        if (!hasRun) { // Test if cookie lowers our true conversion.	

            // Facebook Pixel Lead event tracking	
            if (typeof window !== 'undefined' && (window as any).fbq) {	
                (window as any).fbq('track', 'Lead');	
            }	

            // Google Enhanced Conversion Tracking Code	
            // Needs to fire before the actual event code, otherwise the attribute is not set	
            // ref: https://support.google.com/google-ads/answer/13262500	
            if (typeof window !== 'undefined' && (window as any).dataLayer) {	
                (window as any).dataLayer.push({	
                    'email_address': auth.currentUser?.email,	
                });	
            }	

            // Google Tag Manager code for conversion tracking	
            if (typeof window !== 'undefined' && (window as any).dataLayer) {	
                (window as any).dataLayer.push({	
                    'event': 'SignUp',	
                });	
            }	

            // Bing UET tag for conversion tracking	
            if (typeof window !== 'undefined' && (window as any).uetq) {	
                (window as any).uetq.push(	
                    'event', 'SignUp', {	
                });	
            }	

            // Posthog event sign_up:completed	
            let allCookiesAccepted = false;                    	
            // Add this code to print the cookie	
            const iubCookie = document.cookie	
                .split('; ')	
                .find(row => row.startsWith('_iub_cs-91682523='));	
            if (iubCookie) {	
                // Extract the value part after the '='	
                const cookieValue = iubCookie.split('=')[1];	
                // Decode the URL-encoded string and parse the JSON	
                const decodedCookie = JSON.parse(decodeURIComponent(cookieValue));	

                // Check if purpose exists and all its keys are true	
                if (decodedCookie.purposes) {	
                    const allPurposesAccepted = Object.values(decodedCookie.purposes).every(value => value === true);	
                    if (allPurposesAccepted) {	
                        allCookiesAccepted = true	
                    }	
                }	
            }	

            posthog.capture('sign_up:completed', {	
                cookies_accepted: allCookiesAccepted	
            });	
            // Set a cookie to prevent tracking from firing multiple times	
            sessionStorage.setItem("leadTracking", "true");	
        }	
    };	

    useEffect(() => {	
        if (isFromVerifyPage) {	
            handleSignUpTracking();	
        }	

        // Disable the eslint warning here, since we only want to run this once	
        // eslint-disable-next-line react-hooks/exhaustive-deps	
    }, [isFromVerifyPage]);	

    const toggleCollapse = async (type: string) => {
        const updatedTags = tags.map((tag) =>
            tag.type === type ? { ...tag, collapsed: !tag.collapsed } : tag
        );
        setTags(updatedTags);
        await updateDatabase(updatedTags);
    };

    const handleReorder = (newTags: ITag[]) => {
        setTags(newTags);
        handleOrderChange(newTags);
    };

    const getTagsOrder = async () => {
        const docRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const currentState = docSnapshot.data();
            const newTags = currentState.templatesOrder;
            if (newTags) {
                setTags(newTags);
            }
        }
    };

    const handleOrderChange = async (tags: ITag[]) => {
        await updateDatabase(tags);
    };

    const updateDatabase = async (tags: ITag[]) => {
        const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
        const docSnapshot = await getDoc(writeDataDocRef);
        if (docSnapshot.exists()) {
            await updateDoc(writeDataDocRef, { templatesOrder: tags });
        } else {
            await setDoc(writeDataDocRef, { templatesOrder: tags });
        }

    };

    /* UseEffect Hooks */

    // Add event listener to window resize to update state
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        // Return cleanup function to remove event listener when unmounting
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

    // Track welcome_page loads
    useEffect(() => {
        if (showWelcomePopup && showOnBoarding) {
            posthog.capture('welcome_page:load');
            setActiveModal(ModalTypeEnum.OnboardingModal)
        }
        // Disable the eslint warning here, since we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnBoarding]);

    useEffect(() => {
        loadUserTemplates();
        if (isFromVerifyPage) {
            const user_invited_success = httpsCallable(functions, "user_invited_success");
            user_invited_success()
        }
        // Disable the eslint warning here, since we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        handleSubscriptionTracking();
        // Disable the eslint warning here, since we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFromSuccessPage]);

    useEffect(() => {
        if (auth?.currentUser?.uid) {
            getTagsOrder();
        }
    }, [])

    if (loadingTemplates) {
        return <SpinningLoader />
    } else {
        return (
            <>
                <LayoutInSide
                    onSearch={(e) => {
                        const newQuery = e.target.value.toString().toLowerCase();
                        setQuery(newQuery);

                        // This works by setting a timeout to run after the user stops typing
                        // If the user types again before the timeout runs, the timeout is cleared
                        // and a new one is set
                        // Basically this prevents the logging from running on every keystroke and
                        // only runs after the user stops typing for 1 second

                        // Clear the previous timeout if there is one
                        if (timeoutRef.current) {
                            clearTimeout(timeoutRef.current);
                        }

                        // Set a new timeout
                        timeoutRef.current = setTimeout(() => {
                            // Add posthog tracking for template search
                            if (newQuery.length > 0) {
                                posthog.capture('templates_page:template_search', {
                                    search_query: newQuery
                                });
                            }
                        }, 1000); // 1 second delay
                    }}
                >
                    <div className="w-full">
                        <div className="mb-4 md:mb-8 flex lg:flex-row flex-col md:gap-16 gap-6 items-start justify-between w-full">
                            <div className="flex items-center gap-8 sm:gap-8 justify-start w-full">
                                <h1 className="font-Outfit text-[32px] font-semibold hidden md:block">Vorlagen</h1>
                                <div className="flex lg:justify-end md:justify-end w-full gap-4 justify-between">
                                    <Link
                                        className='md:w-auto sm:w-full w-full group'
                                        to={`/templates/DynamischeVorlage`}
                                    >
                                        <div
                                            className="p-0.5 md:w-auto sm:w-full w-full rounded bg-gradient-to-tr hover:text-white h-8 md:h-12 from-[#C06EEC] to-[#1754FC] hover:scale-95 ">
                                            <button
                                                className="p-4 bg-white group-hover:bg-gradient-to-tr from-[#C06EEC] to-[#1754FC] whitespace-nowrap hover:text-white w-full h-full  py-1.5 md:px-4 px-2 font-poppins md:text-base text-xs rounded">
                                                <span
                                                    className="bg-gradient-to-tr from-[#C06EEC] to-[#1754FC] bg-clip-text text-transparent group-hover:text-white">
                                                    + Eigene Vorlage</span></button>
                                        </div>
                                    </Link>
                                    <Link
                                        className='md:w-auto sm:w-full w-full group'
                                        to={`/templates/Freifeld-Eingabe`}
                                        state={FreiFeldItems}
                                    >
                                        <div
                                            className="p-0.5 md:w-auto sm:w-full w-full rounded bg-gradient-to-tr hover:text-white h-8 md:h-12 from-[#C06EEC] to-[#1754FC] hover:scale-95 ">
                                            <button
                                                className="p-4 bg-white hover:bg-gradient-to-tr from-[#C06EEC] to-[#1754FC] group-hover:text-white whitespace-nowrap w-full h-full py-1.5 md:px-4 px-2 font-poppins md:text-base text-xs rounded">
                                                <span
                                                    className="bg-gradient-to-tr from-[#C06EEC] to-[#1754FC] bg-clip-text text-transparent group-hover:text-white">
                                                    Freifeld-Eingabe</span></button>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        </div>

                        {(query !== "") && (query !== "Other") ? (
                            <div key={`template-tag-search}`}>
                                <div className="w-full flex justify-start items-center mb-4">
                                    <h3 className="pr-6 text-xl font-semibold font-Outfit whitespace-nowrap">Suche</h3>
                                    <div className="bg-#EDEDED w-full border-b flex items-center justify-center"></div>
                                </div>

                                <div className="flex justify-center items-center">
                                    <Swiper
                                        style={{
                                            maxWidth: `${windowWidth > 768 ? windowWidth - 300 : "100%"
                                                }px`
                                        }}

                                        className="h-auto w-full pb-8 pl-1"
                                        modules={[Navigation, Mousewheel]}
                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                        }}
                                        freeMode={true}
                                        spaceBetween={10}
                                        mousewheel={false}
                                        slidesPerGroup={4}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 2.1,
                                                slidesPerGroup: 2
                                            },
                                            768: {
                                                slidesPerView: 2.1,
                                                slidesPerGroup: 2
                                            },
                                            1024: {
                                                slidesPerView: 3.2,
                                                slidesPerGroup: 3
                                            },
                                            1440: {
                                                slidesPerView: 4.2,
                                                slidesPerGroup: 4
                                            }
                                        }}
                                    >
                                        {
                                            allTemplates
                                                .filter((curtemplate) => curtemplate.title.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "").includes(query.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")))
                                                .length > 0 ?
                                                allTemplates
                                                    .filter((curtemplate) => curtemplate.title.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "").includes(query.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")))
                                                    .map((curtemplate, index) => (
                                                        <SwiperSlide className="h-auto" key={`template-2${index}`}>
                                                            <div className="h-full">
                                                                <TemplateCard items={curtemplate} />
                                                            </div>
                                                        </SwiperSlide>
                                                    ))
                                                :
                                                <div className="w-full justify-center items-center text-center">
                                                    <p>
                                                        Es wurden leider keine Vorlagen zu der Suche "{query}" gefunden.
                                                    </p>
                                                </div>
                                        }
                                        <button
                                            className="z-10 left-0 swiper-button-prev after:hidden !bg-transparent">
                                            <svg width="22" height="21" viewBox="0 0 22 21" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect width="22" height="21" rx="2" fill="#9C68EF" />
                                                <path
                                                    transform="scale(-1, 1) translate(-22, 0)"
                                                    d="M10.4414 16.899L16.8162 11.3675C16.8739 11.3175 16.9202 11.2556 16.9519 11.1862C16.9836 11.1168 17 11.0413 17 10.965C17 10.8886 16.9836 10.8132 16.9519 10.7437C16.9202 10.6743 16.8739 10.6125 16.8162 10.5625L10.4414 5.0326C10.4222 5.01589 10.3985 5.00505 10.3733 5.00139C10.3481 4.99772 10.3224 5.00137 10.2992 5.01191C10.2761 5.02244 10.2564 5.03942 10.2426 5.06082C10.2288 5.08222 10.2214 5.10713 10.2214 5.1326L10.2214 6.48257C10.2214 6.55923 10.2547 6.63256 10.313 6.68423L14.5179 10.3325L5.13316 10.3325C5.05983 10.3325 4.99983 10.3925 4.99983 10.4658L4.99983 11.4658C4.99983 11.5391 5.05983 11.5991 5.13316 11.5991L14.5179 11.5991L10.313 15.2474C10.2547 15.2974 10.2214 15.3707 10.2214 15.449L10.2214 16.799C10.2214 16.9123 10.3547 16.974 10.4414 16.899Z"
                                                    fill="white" />
                                            </svg>
                                        </button>
                                        <button
                                            className="z-10 right-0 swiper-button-next after:hidden !bg-transparent">
                                            <svg width="22" height="21" viewBox="0 0 22 21" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect width="22" height="21" rx="2" fill="#9C68EF" />
                                                <path
                                                    d="M10.4414 16.899L16.8162 11.3675C16.8739 11.3175 16.9202 11.2556 16.9519 11.1862C16.9836 11.1168 17 11.0413 17 10.965C17 10.8886 16.9836 10.8132 16.9519 10.7437C16.9202 10.6743 16.8739 10.6125 16.8162 10.5625L10.4414 5.0326C10.4222 5.01589 10.3985 5.00505 10.3733 5.00139C10.3481 4.99772 10.3224 5.00137 10.2992 5.01191C10.2761 5.02244 10.2564 5.03942 10.2426 5.06082C10.2288 5.08222 10.2214 5.10713 10.2214 5.1326L10.2214 6.48257C10.2214 6.55923 10.2547 6.63256 10.313 6.68423L14.5179 10.3325L5.13316 10.3325C5.05983 10.3325 4.99983 10.3925 4.99983 10.4658L4.99983 11.4658C4.99983 11.5391 5.05983 11.5991 5.13316 11.5991L14.5179 11.5991L10.313 15.2474C10.2547 15.2974 10.2214 15.3707 10.2214 15.449L10.2214 16.799C10.2214 16.9123 10.3547 16.974 10.4414 16.899Z"
                                                    fill="white" />
                                            </svg>
                                        </button>
                                    </Swiper>
                                </div>
                                {/*)}*/}
                            </div>
                        ) : (
                            <Reorder.Group values={tags} onReorder={handleReorder}>
                                {tags.map((tag, index) => {
                                    const filteredTemplates = allTemplates.filter(
                                        (curtemplate) => curtemplate.type.toLowerCase() === tag.type.toLowerCase()
                                    );

                                    if (filteredTemplates.length === 0) return null; // Do not display the tag if it doesn't contain filtered items
                                    return (
                                        <Reorder.Item
                                            value={tag}
                                            key={tag.type}
                                            dragListener={draggable}
                                            onDragEnd={() => setDraggable(false)}
                                        >
                                            <motion.div key={`template-tag-${index}`} layout layoutRoot>
                                                <motion.div className="w-full items-center mb-4" layout>
                                                    <div className="flex w-full relative items-center justify-between">
                                                        <motion.div
                                                            className="md:mr-4 cursor-pointer"
                                                            onMouseEnter={() => setDraggable(true)}
                                                            onMouseLeave={() => setDraggable(false)}
                                                            onTouchStart={() => setDraggable(true)}
                                                        >
                                                            <DragandDropIcon />
                                                        </motion.div>
                                                        <button
                                                            onClick={() => toggleCollapse(tag.type)}
                                                            className="flex items-center bg-#EDEDED rounded-md ml-auto"
                                                        >
                                                            {tag.collapsed ? (
                                                                <CollapseArrowUP />
                                                            ) : (
                                                                <CollapseArrowDOWN />
                                                            )}
                                                        </button>
                                                        <h3 className="pr-2 text-xl font-semibold font-Outfit whitespace-nowrap">{tag.type}</h3>
                                                        <div className="bg-#EDEDED w-full items-center border-b flex justify-center"></div>
                                                        <Link
                                                            to={`/templates/list/${tag.type}`}
                                                            className="ml-2 items-center flex text-[#AAA8BD] text-xs"
                                                        >
                                                            <span className="md:w-24">Alle anzeigen</span>
                                                            <FontAwesomeIcon icon={faArrowRight}
                                                                className="pl-2 group-hover:text-white transition-colors duration-200" />
                                                        </Link>
                                                    </div>
                                                </motion.div>
                                                {!tag.collapsed && (
                                                    <div className="flex justify-center items-center">
                                                        <Swiper
                                                            style={{
                                                                maxWidth: `${windowWidth > 768 ? windowWidth - 300 : "100%"}px`
                                                            }}
                                                            className="h-auto w-full pb-8 pl-1"
                                                            modules={[Navigation, Mousewheel]}
                                                            navigation={{
                                                                nextEl: '.swiper-button-next',
                                                                prevEl: '.swiper-button-prev',
                                                            }}
                                                            freeMode={true}
                                                            spaceBetween={10}
                                                            mousewheel={false}
                                                            slidesPerGroup={4}
                                                            breakpoints={{
                                                                320: {
                                                                    slidesPerView: 2.1,
                                                                    slidesPerGroup: 2,
                                                                },
                                                                768: {
                                                                    slidesPerView: 2.1,
                                                                    slidesPerGroup: 2,
                                                                },
                                                                1024: {
                                                                    slidesPerView: 3.2,
                                                                    slidesPerGroup: 3,
                                                                },
                                                                1440: {
                                                                    slidesPerView: 4.2,
                                                                    slidesPerGroup: 4,
                                                                }
                                                            }}
                                                        >
                                                            {filteredTemplates.map((curtemplate, index) => (
                                                                <SwiperSlide className="h-auto" key={`template-2${index}`}>
                                                                    <div className="h-full">
                                                                        <TemplateCard items={curtemplate} />
                                                                    </div>
                                                                </SwiperSlide>
                                                            ))}
                                                            <button className="z-10 left-0 swiper-button-prev after:hidden !bg-transparent">
                                                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="22" height="21" rx="2" fill="#9C68EF" />
                                                                    <path
                                                                        transform="scale(-1, 1) translate(-22, 0)"
                                                                        d="M10.4414 16.899L16.8162 11.3675C16.8739 11.3175 16.9202 11.2556 16.9519 11.1862C16.9836 11.1168 17 11.0413 17 10.965C17 10.8886 16.9836 10.8132 16.9519 10.7437C16.9202 10.6743 16.8739 10.6125 16.8162 10.5625L10.4414 5.0326C10.4222 5.01589 10.3985 5.00505 10.3733 5.00139C10.3481 4.99772 10.3224 5.00137 10.2992 5.01191C10.2761 5.02244 10.2564 5.03942 10.2426 5.06082C10.2288 5.08222 10.2214 5.10713 10.2214 5.1326L10.2214 6.48257C10.2214 6.55923 10.2547 6.63256 10.313 6.68423L14.5179 10.3325L5.13316 10.3325C5.05983 10.3325 4.99983 10.3925 4.99983 10.4658L4.99983 11.4658C4.99983 11.5391 5.05983 11.5991 5.13316 11.5991L14.5179 11.5991L10.313 15.2474C10.2547 15.2974 10.2214 15.3707 10.2214 15.449L10.2214 16.799C10.2214 16.9123 10.3547 16.974 10.4414 16.899Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <button className="z-10 right-0 swiper-button-next after:hidden !bg-transparent">
                                                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="22" height="21" rx="2" fill="#9C68EF" />
                                                                    <path
                                                                        d="M10.4414 16.899L16.8162 11.3675C16.8739 11.3175 16.9202 11.2556 16.9519 11.1862C16.9836 11.1168 17 11.0413 17 10.965C17 10.8886 16.9836 10.8132 16.9519 10.7437C16.9202 10.6743 16.8739 10.6125 16.8162 10.5625L10.4414 5.0326C10.4222 5.01589 10.3985 5.00505 10.3733 5.00139C10.3481 4.99772 10.3224 5.00137 10.2992 5.01191C10.2761 5.02244 10.2564 5.03942 10.2426 5.06082C10.2288 5.08222 10.2214 5.10713 10.2214 5.1326L10.2214 6.48257C10.2214 6.55923 10.2547 6.63256 10.313 6.68423L14.5179 10.3325L5.13316 10.3325C5.05983 10.3325 4.99983 10.3925 4.99983 10.4658L4.99983 11.4658C4.99983 11.5391 5.05983 11.5991 5.13316 11.5991L14.5179 11.5991L10.313 15.2474C10.2547 15.2974 10.2214 15.3707 10.2214 15.449L10.2214 16.799C10.2214 16.9123 10.3547 16.974 10.4414 16.899Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </Swiper>
                                                    </div>
                                                )}
                                            </motion.div>
                                        </Reorder.Item>
                                    );
                                })}
                            </Reorder.Group>
                        )}
                    </div>
                </LayoutInSide>

                <GenericModal
                    isOpen={showDynamicTemplatePopup || showDynamicEditPopup}
                    title={`Deine ${showDynamicTemplatePopup ? 'Persöhnliche Vorlage' : 'Änderung'} ist in Arbeit!`}
                    content={[
                        `Die ${showDynamicTemplatePopup ? 'Erstellung' : 'Änderung'} kann bis zu zwei Minuten dauern. Wenn der Vorgang abgeschlossen ist, erscheint deine Vorlage neben allen anderen Vorlagen auf der folgenden Seite.`
                    ]}
                    buttonText="Perfekt, vielen Dank 😊"
                    image="/assets/images/partying_face.png"
                    imagePlacement="top"
                    onClose={() => {
                        navigate("/templates");
                    }}
                    onButtonClick={() => {
                    }}
                    closeModal={() => {
                        setShowDynamicTemplatePopup(false);
                        setShowDynamicEditPopup(false);
                    }}
                />
            </>
        );
    }
    ;
};

export default TemplatesPage;