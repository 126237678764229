import { functions } from "../config/fbConfig";
import { httpsCallable } from "firebase/functions";
import { Auth, AuthProvider } from "firebase/auth";
import { handleGoogleSignUp } from "../utils/loginUtils";
import { usePostHog } from "posthog-js/react";

interface GoogleSignInButtonProps {
    button_text: string;
    auth: Auth;
    provider: AuthProvider;
    navigate: (arg0: string) => void;
    newsletter_accepted: Boolean;
    backgroundColor?: string;
    setLoading: (arg0: boolean) => void;
    reqData: String | null;
    discount: string | '';
}

const GoogleSignInButton = ({
    button_text,
    auth,
    provider,
    navigate,
    newsletter_accepted,
    setLoading,
    backgroundColor = 'bg-white',
    reqData,
    discount,
}: GoogleSignInButtonProps) => {
    const posthog = usePostHog();
    return (
        <button 
            className={`mb-4 mt-4 h-12 items-center border justify-center py-4 flex shadow w-full rounded-lg font-Outfit text-lg font-medium ${backgroundColor} border-2`}
            onClick={() => {
                setLoading(true);
                handleGoogleSignUp(
                    auth,
                    provider,
                    navigate,
                    httpsCallable,
                    functions,
                    newsletter_accepted,
                    posthog,
                    reqData,
                    discount
                ).catch((error) => {
                    if (error.code === 'auth/popup-closed-by-user') {
                        // You can add a user-friendly message here if needed
                        console.log('Sign-in was cancelled.');
                    } else {
                        // Handle other errors
                        console.error('An error occurred during sign-in:', error);
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }}
        >
            <span className="block mr-2 bg-white rounded-full p-1">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.04 12.2614C23.04 11.4459 22.9668 10.6618 22.8309 9.90912H12V14.3575H18.1891C17.9225 15.795 17.1123 17.013 15.8943 17.8284V20.7139H19.6109C21.7855 18.7118 23.04 15.7637 23.04 12.2614Z"
                        fill="#4285F4"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 23.4998C15.105 23.4998 17.7081 22.47 19.6109 20.7137L15.8943 17.8282C14.8645 18.5182 13.5472 18.9259 12 18.9259C9.00474 18.9259 6.46951 16.903 5.56519 14.1848H1.72314V17.1644C3.61542 20.9228 7.50451 23.4998 12 23.4998Z"
                        fill="#68C132"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.56523 14.1851C5.33523 13.4951 5.20455 12.758 5.20455 12.0001C5.20455 11.2421 5.33523 10.5051 5.56523 9.81506V6.83551H1.72318C0.944318 8.38801 0.5 10.1444 0.5 12.0001C0.5 13.8557 0.944318 15.6121 1.72318 17.1646L5.56523 14.1851Z"
                        fill="#FBBC05"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 5.07386C13.6884 5.07386 15.2043 5.65409 16.3961 6.79364L19.6945 3.49523C17.7029 1.63955 15.0997 0.5 12 0.5C7.50451 0.5 3.61542 3.07705 1.72314 6.83545L5.56519 9.815C6.46951 7.09682 9.00474 5.07386 12 5.07386Z"
                        fill="#EC5962"
                    ></path>
                </svg>
            </span>
            <div
                className={`items-center block text-base font-bold ${backgroundColor === 'bg-white' ? '' : 'text-white'}`}
            >
                {button_text}
            </div>
        </button>
    );
};

export default GoogleSignInButton;