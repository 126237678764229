import { UserGuideStepEnum, updateBonusMap } from "../consts";
import firebase, { auth, collection, doc, firestore, updateDoc, functions, getDoc } from "../config/fbConfig";
import { httpsCallable } from "firebase/functions";
import { QueryDocumentSnapshot, QuerySnapshot } from '@firebase/firestore-types';
import { increment } from "firebase/firestore";


function stringDictToDict(inDict: any) {
    let outDict: { [key: string]: any } = {};
    for (let key in inDict) {
        if (typeof inDict[key] === 'string') {
            if (inDict[key].toLowerCase() === 'true') {
                outDict[key] = true;
            } else if (inDict[key].toLowerCase() === 'false') {
                outDict[key] = false;
            } else if (!isNaN(Number(inDict[key]))) {
                outDict[key] = Number(inDict[key]);
            } else {
                outDict[key] = inDict[key];
            }
        } else {
            outDict[key] = inDict[key];
        }
    }
    return outDict
}
export async function getStripeProducts() {
    const data: { [key: string]: any } = {};
    await firebase
        .firestore()
        .collection('products')
        .get()
        .then((querySnapshot: QuerySnapshot) => {
            querySnapshot.forEach((doc: QueryDocumentSnapshot) => {
                if (doc.data().active) {
                    const role = doc.data().metadata.firebaseRole;
                    data[role] = doc.data().metadata;
                }
            });
        })
        .catch((err: Error) => {
            console.log(err.message);
        });
    let processedData: { [key: string]: any } = {};
    for (let key in data) {
        let dict = data[key];
        processedData[key] = stringDictToDict(dict);
    }
    return processedData;
}
export async function getUserTier(userId: string) {
    const data: { [key: string]: any } = await getStripeProducts();
    let userTier = data["Kostenlos"];
    const userSnapshot = await firebase.firestore().collection('users').doc(userId).get();
    const userData = userSnapshot.data();
    if (!userData?.freetrial) {
        await firebase
            .firestore()
            .collection('users')
            .doc(userId)
            .collection("subscriptions")
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    if (doc.data().status === "active") {
                        userTier = data[doc.data().role];
                    }
                });
            })
            .catch(err => {
                console.log(err.message);
            });
    }
    return stringDictToDict(userTier)
}

export interface UserData {
    userData: any;
    // add known properties here with their types.
    // id: string;
    // name: string;
    // etc...
    productData: any; // replace with the actual type if known
}
export async function getAllUserData(userId: string) {
    const productData = await getUserTier(userId);
    const userSnapshot = await firebase.firestore().collection('users').doc(userId).get();
    return { "userData": userSnapshot.data(), "productData": productData }
};

export async function getUserTemplates(userId: string) {
    const data: any[] = [];
    await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .collection("templates")
        .orderBy("CreatedAt", "desc")
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                let item = doc.data().template_data;
                item.id = doc.id;
                item.disableLink = doc.data().disableLink;
                item.loading = doc.data().disableLink;
                data.push(item);
            });
        })
        .catch(err => {
            console.log(err.message);
        });
    return data;
};

export async function setStopPrompt(userId: string) {
    const userSnapshot = await firebase.firestore().collection('users').doc(userId).collection("writedata").doc('appdata').get();
    let userData = userSnapshot.data() || {}
    userData.stopprompt = true
    await firebase.firestore().collection('users').doc(userId).collection("writedata").doc('appdata').set(userData);
}

export const setUserOnboardingData = async (field: UserGuideStepEnum, state: boolean, posthog: any) => {
    // Handles the Bonus Creations for the user onboarding
    // Always gets called with one of the predefined UserGuideSteps, and a boolean state

    if (!auth.currentUser) return;
    const updateBonus = httpsCallable(functions, "onboardingBonus");

    const writeDataDocRef = doc(collection(firestore, 'users', auth.currentUser.uid, 'writedata'), 'appdata');
    await updateDoc(writeDataDocRef, {
        [`onboardingData.${field}.done`]: state
    });
    // Valid actions ["template_used", "ai_chat_used", "tonality_used", "dynamic_template_used"]

    const text = updateBonusMap[field];
    if (text !== '') {
        posthog.capture('template_progress', {
            step: field,
        });
        updateBonus({ action: text });
    }
};

interface UserDoc {
    creations: number;
    bonus_creations?: number;
}

export async function decrementCreations(userId: string): Promise<boolean> {
    const userRef = doc(firestore, 'users', userId);

    try {
        // First get the current document to check values
        const userSnap = await getDoc(userRef);
        if (!userSnap.exists()) {
            throw new Error('User document not found');
        }

        const userData = userSnap.data() as UserDoc;

        // If creations is already 0, try to use bonus_creations
        if (userData.creations === 0) {
            // Check if bonus_creations exists and is greater than 0
            if (userData.bonus_creations && userData.bonus_creations > 0) {
                await updateDoc(userRef, {
                    bonus_creations: increment(-1)
                });
                return true;
            }
            // No creations or bonus_creations available
            return false;
        }

        // Decrement regular creations
        await updateDoc(userRef, {
            creations: increment(-1)
        });
        return true;

    } catch (error) {
        // Re-throw any errors for handling by the caller
        throw error;
    }
}