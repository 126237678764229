import LayoutInSide from "../../layouts/LayoutInSide";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SpinningLoader from "../../components/loader/spinning_loader";
import Loader from "../../components/loader/loader";
import templates from "../../data/templates.json";
import { httpsCallable } from "firebase/functions";
import {
  functions,
  auth
} from "../../config/fbConfig";
import { toast } from "react-toastify";
import { getUserTemplates } from "../../utils/userUtils";
import { usePostHog } from 'posthog-js/react';
import { SingleLineInput, TextArea } from '../../components/inputs/TextInput';

// Firebase Function Initialization
const deleteDynamicTemplate = httpsCallable(functions, "deleteDynamicTemplate");
const editDynamicTemplate = httpsCallable(functions, "editDynamicTemplate");

const TemplateEditPage = () => {
  const max_input_length = 2000;
  const [loading] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [textLength] = useState(0);
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const [input, setInput] = useState('');
  const [error] = useState("");
  const navigate = useNavigate();
  const [templateID, setTemplateID] = useState("");
  const posthog = usePostHog();

  const templateDetail = useLocation();

  const handleTextFieldChange = (e: any) => {
    setInput(e.target.value);
  }

  const selectedTemplate = templateDetail.state;

  // Posthog event template_edit_page:initialize_edit_template
  useEffect(() => {
    posthog.capture('template_edit_page:initialize_edit_template', { template: selectedTemplate })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // should only run once

  const handleDelete = async () => {
    const confirmation = window.confirm('Dieser Vorgang kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie fortfahren möchten?');
    if (confirmation) {
      try {
        await deleteDynamicTemplate({ document_id: templateID })
        toast.success(
          "Vorlage wurde erfolgreich gelöscht.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );

        // Posthog event template_edit_page:delete_template
        posthog.capture('template_edit_page:delete_template', { template: selectedTemplate });

        navigate("/templates");
      } catch (error) {
        toast.error(
          "Beim Löschen der Vorlage ist ein Fehler aufgetreten.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  }

  const loadUserTemplates = async () => {
    setLoadingTemplates(true);
    const userTemplates = await getUserTemplates(auth.currentUser!.uid);
    // Prepending the user templates to the templates from JSON
    const templates_out = [...userTemplates, ...templates];
    setFilteredTemplates(templates_out);
    setLoadingTemplates(false);
    const cur_template: any = templates_out.filter((item: any) => {
      return item.name === selectedTemplate.title;
    });
    setTemplateID(cur_template[0].id)
    return templates_out
  }

  const handleEdit = async (event: any) => {
    // Log textarea's value
    event.preventDefault();
    setLoadingTemplates(true)
    try {
      editDynamicTemplate({ prompt: input, document_id: templateID })

      // Posthog event template_edit_page:edit_template
      posthog.capture('template_edit_page:edit_template', { template: selectedTemplate });

      navigate("/templates/", { state: { "showEditTemplatePopUp": true } });
    } catch (error) {
      setLoadingTemplates(false)
    }
  }

  useEffect(() => {
    loadUserTemplates();
  // Only runs once, so no dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const renderPlaceholders = () => {
    const template = filteredTemplates.filter((item: any) => {
      return item.name === selectedTemplate.title;
    });
    if (loadingTemplates) {
      return <SpinningLoader />
    }
    const placeholders = template[0].placeholders;

    return placeholders.map((placeholder: any, index: any) => {
      const { label, type } = placeholder;
      return (
        <div className="mb-4" key={index}>
          <div className="flex items-center gap-4 mb-3">
            <label className="block text-base mr-auto" htmlFor={label}>
              {label}
            </label>
            {type === "textarea" && (
              <span className="text-sm text-gray-400">
                {textLength}/{max_input_length}
              </span>
            )}
          </div>

          <div>
            {type === "textarea" && (
              <TextArea
                label="textarea"
              />
            )}

            {type === "input" && (
              <SingleLineInput
                label="text"
              />
            )}
          </div>
          <p className="text-red-500 mt-5">{error}</p>
        </div>
      );
    });
  };


  if (loadingTemplates) {
    return <SpinningLoader />
  } else {
    return (
      <>
        {loading && <Loader />}
        <LayoutInSide>
          {/* Two Column Grid Layout */}
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-8 p-2">
            {/* First Column, should take up one third of space */}
            <div className="col-span-1">
              {/* Header & Subtitle */}
              <div className="mb-9">
                <h1 className=" md:text-[32px] text-xl font-Outfit font-semibold mb-2">Vorlage Ändern</h1>
                <p className="md:text-[15px] text-[12px] text-gray-4000">Sie befinden sich im Vorlagen-Bearbeitungsmodus. Bitte formulieren Sie Ihre Änderungen im Änderungsfeld.</p>
              </div>

              {/* Input Changes Field */}
              <div>
                <div className="mb-5">
                  <textarea
                    style={{ minHeight: '300px' }}
                    onChange={handleTextFieldChange}
                    placeholder={"Beschreiben Sie Ihre Änderungen hier.\n" +
                      "\nBeispiele:\n" +
                      "Fügen Sie bitte ein weiteres Feld mit dem Inhalt xxx hinzu.\n" +
                      "Fügen Sie bitte ein Stilfeld hinzu, in welchem ich einen Beispieltext eingeben kann, der mir gut gefällt.\n" +
                      "Ändern Sie bitte die Icon-Farbe zu Grün.\n" +
                      "Entfernen Sie das Feld yyyy.\n" +
                      "Beginnen Sie den Text immer mit ZZZZ."}
                    rows={8}
                    name="text"
                    id="text"
                    className="w-full bg-white rounded placeholder:text-xs placeholder:text-gray-1000 border-[#EDEDED] h-40"
                  />
                </div>
                {/* Confirm and Delete Buttons */}
                <div className="flex justify-center mt-8">
                  <button
                    onClick={handleEdit}
                    className="py-2 h-12 px-12 w-full rounded bg-secondary text-white font-poppins text-lg font-semibold hover:bg-secondary-600 whitespace-nowrap"
                  >
                    Änderung Durchführen
                  </button>
                </div>
                <div className="flex justify-center mt-8">
                  <button
                    onClick={handleDelete}
                    className="py-2 h-12 w-full px-12 rounded border border-secondary-500 bg-grey text-secondary-500 font-poppins text-lg font-semibold hover:bg-secondary-500 hover:text-white whitespace-nowrap"
                  >
                    Vorlage Löschen
                  </button>
                </div>
              </div>
            </div>
            {/* Second Column, should take up two thirds of space */}
            <div className="col-span-2 mt-9 lg:mt-0 md:h-[80vh] overflow-auto custom-scrollbar md:pr-8">
              {/* Output Field Title */}
              <div className="mb-4">
                <h1 className="md:text-[32px] text-xl font-Outfit font-semibold mb-2">{selectedTemplate.title}</h1>
                <p className="md:text-[15px] text-[12px] text-gray-4000">{selectedTemplate.description}</p>
              </div>
              {/* Rendered Output Template */}
              {renderPlaceholders()}
            </div>
          </div>
        </LayoutInSide>
      </>
    );
  };
};

export default TemplateEditPage;
