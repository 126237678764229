import React, { useState, useEffect } from 'react';
import { BackIcon } from "~/assets/Icons";
import { LoaderIcon } from 'lucide-react';


interface PersonaContentProps {
    analyzeText: string;
    handleAnalyzeFieldChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    setShowPersonaForm: (show: boolean) => void;
    handleAnalyze: (event: React.FormEvent) => void;
    handleBackToContent: () => void;
    inputError: string;
    analyzeError: string;
    isAnalyzing: boolean;
}

const PersonaContent: React.FC<PersonaContentProps> = ({
    analyzeText,
    handleAnalyzeFieldChange,
    setShowPersonaForm,
    handleAnalyze,
    handleBackToContent,
    analyzeError,
    inputError,
    isAnalyzing
}) => {
    const [wordCount, setWordCount] = useState(0);

    useEffect(() => {
        setWordCount(analyzeText.trim().split(/\s+/).filter(word => word.length > 0).length);
    }, [analyzeText]);

    const handleBackClick = () => {
        setShowPersonaForm(false);
        handleBackToContent();
    };

    return (
        <div className="bg-white w-full h-screen">
            <div className="mx-auto items-center sm:h-screen justify-center p-6 md:px-[52px] pt-8">
                <div className="flex items-center cursor-pointer" onClick={handleBackClick}>
                    <BackIcon />
                    <span className="ms-4 text-[16px] sm:text-[18px] font-medium leading-[23px]">Zurück</span>
                </div>
                <h3 className="text-[20px] sm:text-[24px] md:text-[32px] mt-4 font-outfit font-semibold text-textColor">Tonfall hinzufügen</h3>
                <p className="mt-3 text-base font-normal leading-[27px] tracking-[0%] text-left text-[#202356b3] w-full lg:w-[65%]">
                    Schreibe oder füge Inhalte ein, die Deinen Tonfall oder den Deiner Marke zum Ausdruck bringen. Für ein optimales Ergebnis empfehlen wir, zwischen 100 und 500 Wörtern zu verwenden.
                </p>
                <p className="mt-6 sm:mt-8 text-base font-normal leading-6 tracking-[0%] text-left text-[#808080]">
                    ZU ANALYSIERENDER INHALT
                </p>
                <div className="mt-4 flex flex-col">
                    <textarea
                        value={analyzeText}
                        maxLength={5000}
                        rows={11}
                        onChange={handleAnalyzeFieldChange}
                        placeholder=""
                        className={`rounded-[8px] bg-[#20235605] placeholder:text-xs placeholder:text-gray-700 w-full lg:w-[60%] py-2 px-3 mb-2 resize-none ${inputError ? 'border-red-500' : 'border-0'}`}
                    />
                    {inputError && (
                        <span className="text-red-500 text-sm">{inputError}</span>
                    )}
                    {analyzeError && (
                        <span className="text-red-500 text-sm">{analyzeError}</span>
                    )}
                    <div className="flex justify-end items-center w-full md:w-[60%]">
                        <span className="text-[12px] font-medium leading-[18px] text-[#80808099]">{wordCount} Wörter</span>
                    </div>
                    <div className="flex space-x-4 mt-2">
                        <button
                            className="flex w-fit items-center justify-center p-[10px] rounded-[4px] font-medium bg-secondary text-white text-[14px] hover:bg-secondary-600"
                            onClick={handleAnalyze}
                            disabled={isAnalyzing}
                        >
                            {isAnalyzing ? (
                                <>
                                    <LoaderIcon className="animate-spin h-5 w-5 text-white mr-2" />
                                    Analysiere...
                                </>
                            ) : (
                                "Tonfall analysieren"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonaContent;
