import { CheckIcon } from "~/assets/Icons";

const PlanCard = ({
  title2,
  price,
  discountedPrice,
  planIncludes,
  enabled,
  id,
  createUserAndCheckout,
  words,
  CTA,
  monthlyPrice
}: {
  title2: any;
  price: number;
  discountedPrice?: number;
  planIncludes: string[];
  enabled: boolean;
  id: string;
  createUserAndCheckout?: any;
  words: any;
  CTA: string;
  monthlyPrice: number;
}) => {
  const isDiscounted = discountedPrice !== undefined && discountedPrice < price;
  const title = title2?.name === 'Starter' ? 'Start' : title2?.name === 'Creator' ? 'Pro' : title2?.name;

  return (
    <div className="flex flex-col items-center bg-white rounded-[10px] border border-slate-200 shadow-[0px_10px_60px_0px_rgba(38,45,118,0.08)] sm:p-[23px] sm:pb-[29px]  p-[30px_14px] relative">
      <div className="h-[170px]">
        <h3 className="text-center font-bold text-[36px] mb-[10px] text-black uppercase">{title}</h3>
        <div className="flex justify-center items-baseline">
          <h4 className="leading-[1]">
            {isDiscounted ? (
              <>
                {(enabled || isDiscounted) && (
                  <div className="text-neutral-400 flex justify-center items-center mb-[2px] transition-all duration-700 ease-out opacity-100 transform scale-100 animate-fade-in-scale">
                    <span data-testid={`${title}-original-price`} className="text-[20px] line-through">
                      {enabled ? (price/6).toString().replace('.', ',') : price.toString().replace('.', ',')}
                    </span>
                    <span className="text-xs !font-light line-through">€</span>
                  </div>
                )}
                <div>
                  <span data-testid={`${title}-discounted-price`} className="font-medium text-[38px] text-[#F76767]">
                    {enabled ? (discountedPrice / 12).toFixed(2).replace('.', ',') : discountedPrice.toString().replace('.', ',')}
                  </span>
                  <span className="text-base !font-light text-[#F76767]">€</span>
                </div>
              </>
            ) : (
              <>
                {enabled && (
                  <div className="text-neutral-400 flex justify-center items-center mb-[2px] transition-all duration-700 ease-out opacity-100 transform scale-100 animate-fade-in-scale">
                    <span data-testid={`${title}-original-price`} className="text-[20px] line-through">
                      {monthlyPrice.toString().replace('.', ',')}
                    </span>
                    <span className="text-xs !font-light line-through">€</span>
                  </div>
                )}
                <div>
                  <span 
                    data-testid={`${title}-current-price`}
                    className={`font-medium text-[48px] transition-all duration-700 ease-out transform ${enabled ? 'text-[#F76767] scale-110' : '!text-neutral-700 scale-100'}`}
                  >
                    {enabled ? (price / 12).toFixed(2).replace('.', ',') : price.toString().replace('.', ',')}
                  </span>
                  <span
                    className={`text-[22px] !font-light transition-all duration-700 ease-out transform ${enabled ? 'text-[#F76767] scale-110' : 'text-[#797979] scale-100'}`}
                  >
                    €
                  </span>
                </div>
              </>
            )}
          </h4>

        </div>
        {isDiscounted && (
          <p className="text-center text-[18px] text-green-600 font-semibold mb-[10px]">
            Spare zusätzlich {((price - discountedPrice) / price * 100).toFixed(0)}%
            </p>
        )}
        <h5 className="text-center text-[15px] text-[#797979]">
          / Monat
        </h5>
      </div>
      <ul className="h-[260px]">
        {planIncludes.map((planIncludes, index) => (
          <li key={`plan-include-${index}`} className="leading-[25px] mb-[12px]  flex items-center gap-[12px]">
            <CheckIcon />
            <h5 className="flex-1 inline-block font-light text-neutral-800">{planIncludes}</h5>
          </li>
        ))}
      </ul>
      <hr className="border-[#E9E9E9] h-1 w-full mt-[10px] mb-[20px]" />
      <button
        onClick={() => createUserAndCheckout(id, words)}
        type="button"
        className="max-w-[200px] justify-center items-center px-6 py-3 border text-base font-semibold rounded-[8px] shadow-sm hover:text-white border-secondary-500 text-secondary hover:bg-secondary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 transition duration-300 ease-in-out transform hover:scale-105"
      >
        {CTA}
      </button>
      <p className="text-center text-neutral-400 text-[12px] capitalize mt-[18px]">
        Kündige jederzeit - MwSt. inklusive
      </p>
    </div>
  );
};

export default PlanCard;