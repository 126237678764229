import { useState, useEffect, useCallback } from "react";
import LayoutInSide from "../../layouts/LayoutInSide";
import firebase, { collection, doc, firestore, getDoc, onSnapshot, auth } from "../../config/fbConfig";
import { functions } from "../../config/fbConfig";
import { useManageStripeSubscription } from '../../utils/ManageStripeSubscription';
import { toast } from "react-toastify";
import {
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import SpinningLoader from "../../components/loader/spinning_loader";
import MiniSpinningLoader from "../../components/loader/mini_spinning_loader";
import { UserData, getAllUserData } from "../../utils/userUtils";
import Switch from "react-switch";
import { Crisp } from "crisp-sdk-web";
import { useShareableData } from "../../providers/ShareableDataProvider";
import { UserGuideStepEnum } from "../../consts";

const SettingsPage = () => {

  // State Variables
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<UserData>({ "userData": "test", "productData": "test" });
  const [newsletter_loading, setNewsletterLoading] = useState(false);
  const [upgradeButtonLoading, setUpgradeButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    password: "",
    freetrial: false,
    newsletter: false,
    upgradeButton: true
  });
  const { showOnBoarding, completeOnBoardingStep } = useShareableData();
  const subscription = !formData.freetrial;

  // Providers
  const manageStripeSubscription = useManageStripeSubscription();

  // Functions & Event Handlers

  const openIubendaPreferences = async (event: React.MouseEvent) => {
    // Prevent the default action
    event.preventDefault();

    // Get the _iub element from the DOM, call the 'openPreferences' function on it
    // Ref: https://www.iubenda.com/en/help/1205-how-to-configure-your-cookie-solution-advanced-guide#api
    const iub = (window as any)._iub || [];
    iub.cs.api.openPreferences();
  }

  const handlePwReset = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, formData?.email)
      .then(() => {
        toast.success('Passwort-Reset E-Mail wurde verschickt.', {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
  };

  const getUserDetails = async () => {
    setLoading(true);
    const retrievedUserData = await getAllUserData(auth.currentUser!.uid);
    setUserData(retrievedUserData);
    // Some fallback logic for older sign-ups where we dont have a fullname field
    const fullName = retrievedUserData.userData?.fullname !== undefined ? retrievedUserData.userData?.fullname : `${retrievedUserData.userData?.firstname} ${retrievedUserData.userData?.lastname}`;
    setFormData({
      full_name: fullName,
      email: retrievedUserData.userData?.email,
      password: "",
      freetrial: retrievedUserData.userData?.freetrial || retrievedUserData.userData?.freetrialused,
      newsletter: retrievedUserData.userData?.newsletter?.subscribed === undefined ? false : retrievedUserData.userData?.newsletter?.subscribed,
      upgradeButton: retrievedUserData.userData?.visuals?.UpgradeButton === undefined ? true : retrievedUserData.userData?.visuals?.UpgradeButton
    });
    setLoading(false);
  };

  const subscribeUserData = useCallback(async () => {
    if (auth.currentUser) {
      const userDocRef = doc(collection(firestore, "users"), auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        onSnapshot(userDocRef, (userDocSnap: any) => {
          if (userDocSnap.exists()) {
            const userDocData = userDocSnap.data();
            setUserData({ "userData": userDocData, "productData": userData.userData.productData })
            setFormData({ ...formData, upgradeButton: userDocData?.visuals?.UpgradeButton === undefined ? true : userDocData?.visuals?.UpgradeButton });
          } else {
            // Handle the case when the document does not exist
          }
        });
      } else {
        // Handle the case when the document does not exist
      }
    } else {
      // Handle the case when auth.uid is falsy (user is not authenticated)
    }
    // Disable the eslint warning here, since we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);

  const handleUpgradeButtonSwitch = async (checked: boolean) => {
    // set the newsletter_loading flag to true
    setUpgradeButtonLoading(true);

    const updateUpgradeButton = httpsCallable(functions, "update_user_settings");

    // declare the data variable with a default value
    let data = { change_data: "Upgrade Button" };

    updateUpgradeButton(data).then((result) => {
      const data = result.data as {
        success: boolean;
      };
      if (data.success) {
        setFormData({ ...formData, upgradeButton: checked });
        // set the newsletter_loading flag to false
        setUpgradeButtonLoading(false);
      }
    });
  }

  // UseEffect to handle the Newsletter Switch
  // Should run every time the formData.newsletter value changes
  const handleNewsletterSwitch = async (checked: boolean) => {
    // set the newsletter_loading flag to true
    setNewsletterLoading(true);

    const updateUserDataFunction = httpsCallable(functions, "update_user_data_py");
  
    // declare the data variable with a default value
    let data = { newsletter_subscribed: checked };
    // declare a flag to check if the newsletter is subscribed to the first time
    let first_subscription_flag = false;

    // if the newsletter is subscribed to the first time, also set the first_newsletter_subscription flag to true
    if (checked && userData.userData?.newsletter?.first_subscription !== true) {
      data = { newsletter_subscribed: checked, first_newsletter_subscription: true } as { newsletter_subscribed: boolean; first_newsletter_subscription: boolean };
      first_subscription_flag = true;
    }

    updateUserDataFunction(data).then((result) => {
      const data = result.data as {
        success: boolean;
      };
      if (data.success) {
        let text = "Einstellungen wurden erfolgreich aktualisiert.";
        // If the user subscribed for the first time (and is not in the unlimited tier), inform him about the 3 bonus creations
        if (first_subscription_flag && userData.userData?.unlimited !== true) {
          text = "Einstellungen wurden erfolgreich aktualisiert. Du hast 3 Bonus-Texte erhalten. 🔥";
          // This gets a little unreadable, what this does is this updates the userData.newsletter map to set the first_subscription flag to true
          setUserData({
            ...userData,
            userData: {
              ...userData.userData,
              newsletter: {
                ...userData.userData.newsletter,
                first_subscription: true
              }
            }
          });
        }
        toast.success(text, {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setFormData({ ...formData, newsletter: checked });
        // set the newsletter_loading flag to false
        setNewsletterLoading(false);
      } else {
        toast.error("Einstellungen konnten nicht aktualisiert werden. Bitte versuchen sie es später noch einmal.", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setFormData({ ...formData, newsletter: checked });
        // set the newsletter_loading flag to false
        setNewsletterLoading(false);
      }
    });

    if (showOnBoarding && checked) {
      completeOnBoardingStep(UserGuideStepEnum.NEWSLETTER)
    }
  }

  const deleteaccount = async (priceId: any) => {
    const user = firebase.auth().currentUser;
    const email = prompt("Bitte geben Sie ihre Email Adresse ein um ihr Konto zu löschen:");
    if (email && user?.email === email) {
      setLoading(true);
      const functionRef = httpsCallable(functions, "delete_user_and_cancel_subscriptions_py");
      await functionRef();

      // Reset Crisp Session
      Crisp.setTokenId(); // 1. Clear the token value
      Crisp.session.reset(); // 2. Unbind the current session

      await firebase.auth().signOut();
      setLoading(false);
      toast.success("Account wurde erfolgreich gelöscht.", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Account Löschen hat nicht geklappt, bitte geben Sie ihre E-Mail Adresse ein.", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  /* UseEffect Hooks */

  useEffect(() => {
    if (formData.email === "") {
      getUserDetails();
    }
  });

  useEffect(() => {
    subscribeUserData();
  }, [subscribeUserData]);

  if (loading === true)
    return (
      <SpinningLoader />
    );

  return (
    <LayoutInSide>
      <div className="relative">
        <div className="py-[30px]">
          <h3 className="text-[32px] font-outfit font-semibold text-textColor tracking-[0.32px]">
            Einstellungen
          </h3>
        </div>

        <div className="max-w-[496px] mx-auto">

          <div>
            <h3 className="text-[20px] text-textColor tracking-[0.2px] mb-[32px] font-semibold">
              Account Details
            </h3>{" "}
            <form>
              <div className="mb-[20px]">
                <div className="flex items-center justify-between gap-[10px] mb-[9px]">
                  <label htmlFor="" className="text-textColor font-medium">
                    Vorname & Nachname
                  </label>
                </div>
                <input
                  type="text"
                  required
                  value={formData.full_name}
                  readOnly
                  className="w-full border border-white text-[14px] p-[13.5px_14px] bg-white focus:border-secondary rounded-[8px] outline-none font-light"
                />
              </div>
              <div className="mb-[20px]">
                <div className="flex items-center justify-between gap-[10px] mb-[9px]">
                  <label htmlFor="" className="text-textColor font-medium">
                    Email Addresse
                  </label>
                </div>
                <input
                  type="email"
                  required
                  value={formData.email}
                  readOnly
                  className="w-full border border-white text-[14px] p-[13.5px_14px] bg-white focus:border-secondary rounded-[8px] outline-none font-light"
                />
              </div>
              {(!userData.userData.newsletter?.subscribed && !formData.newsletter) &&
              <div className="flex items-center mb-[20px]">
                <label htmlFor="subscribe" className="flex items-center cursor-pointer">
                  {newsletter_loading ? (
                    <div className='w-12 h-12'>
                      <MiniSpinningLoader />
                    </div>
                  ) : (
                    <Switch
                      onChange={handleNewsletterSwitch}
                      checked={formData.newsletter}
                      disabled={newsletter_loading}
                    />)}
                  <span className="ml-2 text-base font-medium">Ich möchte über Rabatte, Angebote und andere Updates per E-Mail informiert werden.</span>
                </label>
              </div>
              }
              {!userData.userData?.freetrial &&
                <div className="flex items-center mb-[20px]">
                  <label htmlFor="subscribe" className="flex items-center cursor-pointer">
                    {upgradeButtonLoading ? (
                      <div className='w-12 h-12'>
                        <MiniSpinningLoader />
                      </div>
                    ) : (
                      <Switch
                        onChange={handleUpgradeButtonSwitch}
                        checked={formData.upgradeButton}
                        disabled={upgradeButtonLoading}
                      />)}
                    <span className="ml-2 text-base font-medium">Upgrade Button anzeigen</span>
                  </label>
                </div>
              }
              <div className="grid grid-cols-1 gap-[25px]">
                <button
                  className="px-8 py-3 border border-transparent text-base font-medium rounded-[8px] shadow-sm text-white bg-secondary-500 hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                  onClick={async (event) => {
                    event.preventDefault();
                    setLoading(true);
                    await manageStripeSubscription();
                  }}
                >
                  {subscription === false ? ("Abonnement upgraden") : ("Abonnement verwalten")}
                </button>
                <div className="text-center">
                  <button
                    onClick={openIubendaPreferences}
                    className="text-secondary font-medium hover:underline transition-all"
                  >
                    Cookie Einstellungen
                  </button>
                </div>
                <div className="text-center">
                  <button
                    onClick={handlePwReset}
                    className="text-secondary font-medium hover:underline transition-all"
                  >
                    Passwort zurücksetzen
                  </button>
                </div>
                <div className="text-center">
                  <button
                    className="text-secondary font-medium hover:underline transition-all"
                    onClick={deleteaccount}
                  >
                    Account löschen
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LayoutInSide>
  );
};

export default SettingsPage;
