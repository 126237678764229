import { useState, useEffect } from 'react';

export const useTimeLeftUntilMidnight = () => {
    const [timeLeft, setTimeLeft] = useState('');
    const [timeLeftColonFormat, setTimeLeftColonFormat] = useState('');

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const nextMidnight = new Date(now);
            nextMidnight.setHours(24, 0, 0, 0);

            const difference = nextMidnight.getTime() - now.getTime();
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            // Format 1: 'hh:mm:ss'
            setTimeLeftColonFormat(
                `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
                    .toString()
                    .padStart(2, '0')}`
            );

            // Format 2: 'hh h mm m ss s'
            setTimeLeft(
                `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds
                    .toString()
                    .padStart(2, '0')}s`
            );
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, []);

    return { timeLeft, timeLeftColonFormat };
};
